import React, { useContext } from "react";
import { Accordion, Progress  } from "flowbite-react";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import RemoveCircleOutlineSharpIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import { useLocation } from "react-router-dom";
import Carousel from "../Components/Carousel";
import banner from "../Banner/Web Banner - 1.png";

import {
  arrayRemove,
  arrayUnion,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { AppContext } from "../Services/Context/Appcontext";
import { database } from "../Services/firebaseconfig";

const Product = () => {
  const { logUser, userCart } = useContext(AppContext);
  const { state } = useLocation();

  const thisProduct = state.product;
  console.log(state.product);

  const increment = (product) => {
    const newproduct = { ...product, qty: product.qty + 1 };
    console.log(newproduct);
    updateDoc(doc(database, "cart", logUser.uid), {
      products: arrayUnion(newproduct),
    }).then(
      updateDoc(doc(database, "cart", logUser.uid), {
        products: arrayRemove(product),
      })
    );
  };

  async function addToCart(product) {
    const productWQty = { ...product, qty: product.MOQ };

    if (userCart && userCart.products) {
      const productExists = userCart.products.some(
        (item) => item.id === product.id
      );
      if (productExists) {
        const existingProduct = userCart.products.find(
          (item) => item.id === product.id
        );
        increment(existingProduct);
        return; // Exit function since product is already in the cart
      }
      // If product doesn't exist, add it to the cart
      await setDoc(
        doc(database, "cart", logUser.uid),
        {
          products: [...userCart.products, productWQty],
        },
        { merge: true }
      );
    } else {
      // If cart data doesn't exist, create a new cart with the product
      await setDoc(doc(database, "cart", logUser.uid), {
        products: [productWQty],
      });
    }
  }

  return (
    <div className="flex flex-col justify-around">
      <div className=" flex items-center">
        <div className="w-[800px]">
          <Carousel images={thisProduct.image} />
        </div>
        <div className="flex flex-col justify-between">
          <h1>{thisProduct.name}</h1>
          <p>({thisProduct.details})</p>
          <p className="flex">
            {Array.from(
              { length: Number(state.product.rating) },
              (_, index) => (
                <StarOutlinedIcon key={index} />
              )
            )}
            {Array.from(
              { length: 5 - Number(state.product.rating) },
              (_, index) => (
                <StarBorderOutlinedIcon
                  style={{ color: "gray" }}
                  key={index + Number(state.product.rating)}
                />
              )
            )}{" "}
            <span className="ml-1">
              ({thisProduct.ratingBy.length} customer reviews)
            </span>
          </p>
          {thisProduct.offer_price !== "" ? (
            <>
              <p className="font-bold">
                ₹{thisProduct.offer_price}
                <span className="line-through font-normal ml-2">
                  ₹{thisProduct.price}
                </span>
              </p>
            </>
          ) : (
            <p className="font-bold">₹{thisProduct.price}</p>
          )}
          <p>Benefits:</p>
          <ul className="list-disc">
            {thisProduct.benefits?.map((benfit, key) => (
              <li key={key} className="ml-7">
                {benfit}
              </li>
            ))}
          </ul>
          <p>
            In Stock: <span>{thisProduct.stock}</span>
          </p>
          <div className="flex items-center gap-2">
            Quantity:
            <span className="flex justify-around border-[1px] border-[#DCDBDB] w-[120px] py-1 rounded-lg">
              <button>
                <RemoveCircleOutlineSharpIcon />
              </button>
              <p className="font-semibold w-4 text-center">{thisProduct.MOQ}</p>
              <button>
                <AddCircleOutlineSharpIcon />
              </button>
            </span>
          </div>
          <button
            onClick={() => addToCart(state.product)}
            className="text-center text-white bg-green-primary w-full rounded-xl  uppercase font-bold py-2 mt-4"
          >
            Add to Cart
          </button>
          <hr />
          <p>SKU: {thisProduct.sku}</p>
          <p>Category: {thisProduct.category}</p>
        </div>
      </div>

      {/* Accordion section */}

      <Accordion className="mx-14 mt-5">
        <Accordion.Panel>
          <Accordion.Title className="text-green-primary">DESCRIPTION</Accordion.Title>
          <Accordion.Content>
            <p className="mb-2 text-black dark:text-gray-400">
              {thisProduct.desc_title}
            </p>
            <p className="text-black dark:text-gray-400">Indications: </p>
            <ul className="list-disc">
              {thisProduct.indications?.map((indication, key) => (
                <li key={key} className="ml-5">
                  {indication}
                </li>
              ))}
            </ul>
            <p className="text-black dark:text-gray-400">Direction for use: </p>
            <ul>
              {thisProduct.use?.map((use, key) => (
                <li key={key}>{use}</li>
              ))}
              or as directed by the physician.
            </ul>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
      <Accordion className="mx-14 mt-5" collapseAll>
        <Accordion.Panel>
          <Accordion.Title className="text-green-primary">
            REVIEWS ({thisProduct.ratingBy.length})
          </Accordion.Title>
          <Accordion.Content>
            <p>Customer reviews</p>
            <div className="flex items-center justify-center border-gray-200 border-[1px] p-7">
              <div className="basis-1/12 border-r-[1px] flex flex-col items-center">
                <p className="p-10 border-[1px] w-[80px] h-[80px] flex justify-center items-center rounded-full border-green-primary">{thisProduct.ratingBy.length}</p>
                <p className="text-center">{thisProduct.ratingBy.length} ratings</p>
              </div>
              <div className="flex flex-col basis-6/12">
                <span className="flex items-center">
                  {" "}
                  {Array.from({ length: Number(5) }, (_, index) => (
                    <StarOutlinedIcon key={index} style={{color:"#FFC700"}}/>
                  ))}
                  <Progress progress={50} className="w-[400px] ml-4" color="green" size="lg"/>
                </span>
                <span className="flex items-center">
                  {" "}
                  {Array.from({ length: Number(4) }, (_, index) => (
                    <StarOutlinedIcon key={index} style={{color:"#FFC700"}}/>
                  ))}
                  <Progress progress={50} className="w-[400px] ml-4" color="green" size="lg"/>
                </span>
                <span className="flex items-center">
                  {" "}
                  {Array.from({ length: Number(3) }, (_, index) => (
                    <StarOutlinedIcon key={index} style={{color:"#FFC700"}}/>
                  ))}
                  <Progress progress={50} className="w-[400px] ml-4" color="green" size="lg"/>
                </span>
                <span className="flex items-center">
                  {" "}
                  {Array.from({ length: Number(2) }, (_, index) => (
                    <StarOutlinedIcon key={index} style={{color:"#FFC700"}}/>
                  ))}
                  <Progress progress={50} className="w-[400px] ml-4" color="green" size="lg"/>
                </span>
                <span className="flex items-center">
                  {" "}
                  {Array.from({ length: Number(1) }, (_, index) => (
                    <StarOutlinedIcon key={index} style={{color:"#FFC700"}}/>
                  ))}
                  <Progress progress={50} className="w-[400px] ml-4" color="green" size="lg"/>
                </span>
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
      <Accordion className="mx-14 mt-5" collapseAll>
        <Accordion.Panel>
          <Accordion.Title className="text-green-primary">
          RELATED PRODUCTS
          </Accordion.Title>
          <Accordion.Content></Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
};

export default Product;
