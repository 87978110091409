import React, { useState } from 'react';
import "../Assets/Styles/Carousel.css"
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel flex items-center justify-center sm:w-[80%] w-full h-full">
      <button className="prev-button" onClick={prevSlide}>< ArrowCircleLeftOutlinedIcon fontSize='large'/></button>
      <div className="slide-container">
        {images.map((slide, index) => (
          <div
            key={index}
            className={index === currentIndex ? 'slide active h-full w-full' : 'slide'}
          >
            {index === currentIndex && <img src={slide} alt={`Slide ${index}`} />}
          </div>
        ))}
      </div>
      <button className="next-button" onClick={nextSlide}>< ArrowCircleRightOutlinedIcon fontSize='large'/></button>
    </div>
  );
};

export default Carousel;