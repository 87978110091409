import React from 'react'

const Account_details = () => {
  return (
<>

<section className="border-[1px] border-[#DCDBDB] rounded-[10px] overflow-hidden">
              <form
                action="submit"
                className="flex flex-col gap-6 mt-4 mb-8 px-4"
              >
                <div className="flex sm:flex-row flex-col gap-6 mt-4 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="first name">First Name*</label>
                    <input
                        required
                      type="text"
                      name="first name"
                      id="first-name"
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="last name">Last Name*</label>
                    <input
                        required
                      type="text"
                      name="last name"
                      id="last-name"
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="display name">Display Name*</label>
                  <input
                    required
                    type="text"
                    name="display name"
                    id="display-name"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                  <i className='text-sm'>This will be how your name is displayed in the account section and in reviews</i>
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="street address">Email address*</label>
                  <input
                    required
                    type="email"
                    name="email address"
                    id="email-address"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>

                <h1 className='text-2xl font-bold'>Password Change</h1>

                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="current-pass">Current password (leave blank to leave unchanged)*</label>
                  <input
                    type="password"
                    name="current-pass"
                    id="current-pass"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="new-pass">New password (leave blank to leave unchanged)*</label>
                  <input
                    type="password"
                    name="new-pass"
                    id="new-pass"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="confirm-new-pass">Confirm new password*</label>
                  <input
                    type="password"
                    name="confirm-new-pass"
                    id="confirm-new-pass"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>

                <button className='bg-green-primary py-2 px-6 rounded-lg font-semibold mx-auto text-white'>Save Address</button>
              </form>
            </section>

</>
)
}

export default Account_details