import React from "react";

const Aboutus = () => {
  return (
    <>
      <div className="flex justify-center ">
        <img
          className="size-1/4"
          src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/kolmin-logo-about-us.png?alt=media&token=375be3d5-9e1d-4b4d-b709-6e90f8c352a2"
        />
      </div>

      <h1 className="text-base italic family-roboto font-medium text-center">
        “Ayurveda not only alleviates the symptoms but also treats the disease
        from its root cause to <br />
        ensure that the ailment is wholly removed from the system.”
      </h1>
      <div className="w-full sm:h-[250px] h-fit flex sm:flex-row flex-col gap-[40px] justify-center mt-16 sm:pr-4 sm:px-0 px-4">
        <img
          className="sm:h-full w-full sm:w-fit"
          src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/About%20Kolmin.png?alt=media&token=a09a57ba-ecf6-49fc-a6dd-cc7d905a7a89"
        />
        <p className="sm:w-3/6 w-full ">
          Kolmin Healthcare established in 2019 intending to provide the best
          herbal products in the market.We at Kolmin Healthcare are dedicated to
          providing 100% genuine products without compromising on the product
          safety, quality and efficiency.
          <p className="mt-5">
            {" "}
            Apart from 100% genuine products we also share a loving and caring
            relationship with our customers and partners.
          </p>
          <p className="mt-5">
            {" "}
            We are a company of strong ethics. With an ambition to build a truly
            best in class Herbal medicine brand.
          </p>
          <p className="mt-5">
            {" "}
            Come be a part of Kolmin Healthcare. Let’s start a journey together
            of mutual profit trust and relationships.
          </p>
        </p>
      </div>
      <div className="flex justify-center sm:flex-row flex-col w-full sm:gap-[300px] gap-[100px] mt-16">
        <div className="flex sm:flex-row flex-col items-center gap-x-[40px]">
          <img
            className=" h-[100px]"
            src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/eye-fill.png?alt=media&token=882e68c4-c442-4ef6-8f3f-ca28577c816c"
          />
          <div className="w-[300px]">
            <h1 className="font-lg font-semibold">Vision</h1>
            <p className="h-[120px]">
              To establish health care business into the area where it is
              untouched for the time being, to make strong, everlasting
              penetration in the World market. “Make in India for the World”.
            </p>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col items-center gap-x-[40px]">
          <img
            className=" h-[100px]"
            src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/octicon_goal-16.png?alt=media&token=091159ff-3ce4-451f-853b-033277976a4d"
          />
          <div className="w-[300px]">
            <h1 className="font-lg font-semibold">Mission</h1>
            <p className="h-[120px]">
              Our mission is to spread the health-giving benefits to mankind by
              reducing the healthcare cost by promoting effective & affordable
              Ayurvedic way of lifestyle.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-around items-center sm:flex-row flex-col gap-10 sm:gap-0 mt-16">
        <div className=" flex flex-col items-center w-[180px] text-center gap-y-[5px]">
          <img className="rounded-full" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/100%25%20Ayurvedic.png?alt=media&token=afa01bce-46da-4ab9-96c6-576de6a06c8e" />
          <h1 className="text-xs font-semibold">100% AYURVEDIC</h1>
          <p className=" text-sm">
            100% Ayurvedic and Effective treatment for your body.
          </p>
        </div>
        <div className=" flex flex-col items-center w-[180px] text-center gap-y-[5px]">
          <img className="rounded-full" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/GMP%20Certification.png?alt=media&token=7fe588b9-b70f-4888-9a1d-0868790dba88" />
          <h1 className="text-xs font-semibold">GMP CERTIFIED</h1>
          <p className="text-sm">
            Manufactured under GMP certified Facilities.
          </p>
        </div>
        <div className=" flex flex-col items-center w-[180px] text-center gap-y-[5px]">
          <img className="rounded-full" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/Made%20in%20India.png?alt=media&token=e8cb757b-b4ef-493c-9181-ec839700d476" />
          <h1 className="text-xs font-semibold">MADE IN INDIA</h1>
          <p className="text-sm">Proudly made in India for the World.</p>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
