import React from "react";
import Inventory from "../Components/Admin panel/Inventory";
import AllOrders from "../Components/Admin panel/AllOrders";

import CategoryIcon from "@mui/icons-material/Category";
import { Link } from "react-router-dom";

const AdminPanel = () => {
  return (
    <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-y-8">
      <Link to="/my_account/admin_panel/products/add">
        <div className="w-[150px] h-[150px] flex flex-col items-center justify-center gap-5 border-green-primary border-2 rounded-xl hover:bg-green-primary hover:text-white">
          <CategoryIcon className="w-[50px] text-4xl" fontSize="4.5rem" />
          <p className="text-center">Add/Remove Product</p>
        </div>
      </Link>
      <Link to="/my_account/admin_panel/products/modify">
        <div className="w-[150px] h-[150px] flex flex-col items-center justify-center gap-5 border-green-primary border-2 rounded-xl hover:bg-green-primary hover:text-white">
          <CategoryIcon className="w-[50px] text-4xl" fontSize="4.5rem" />
          <p className="text-center">Modify Product</p>
        </div>
      </Link>
      <Link to="/my_account/admin_panel/products/stock">
        <div className="w-[150px] h-[150px] flex flex-col items-center justify-center gap-5 border-green-primary border-2 rounded-xl hover:bg-green-primary hover:text-white">
          <CategoryIcon className="w-[50px] text-4xl" fontSize="4.5rem" />
          <p className="text-center">Stock Management</p>
        </div>
      </Link>
      <Link>
        <div className="w-[150px] h-[150px] flex flex-col items-center justify-center gap-5 border-green-primary border-2 rounded-xl hover:bg-green-primary hover:text-white">
          <CategoryIcon className="w-[50px] text-4xl" fontSize="4.5rem" />
          <p className="text-center">Blogs</p>
        </div>
      </Link>
      <Link to="/my_account/admin_panel/orders">
        <div className="w-[150px] h-[150px] px-4 flex flex-col items-center justify-center gap-5 border-green-primary border-2 rounded-xl hover:bg-green-primary hover:text-white">
          <CategoryIcon className="w-[50px] text-4xl" fontSize="4.5rem" />
          <p className="text-center">Order Management</p>
        </div>
      </Link>
      <Link>
        <div className="w-[150px] h-[150px] flex flex-col items-center justify-center gap-5 border-green-primary border-2 rounded-xl hover:bg-green-primary hover:text-white">
          <CategoryIcon className="w-[50px] text-4xl" fontSize="4.5rem" />
          <p className="text-center">Enquiry Form</p>
        </div>
      </Link>
    </div>
  );
};

export default AdminPanel;
