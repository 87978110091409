import React from 'react'
import ProductCard from './ProductCard'
import { Link } from 'react-router-dom'

const ProductsArray = ({array, col}) => {
    const products = [
        {
            docId:"E3d8ig2#87433fd",
            sku:"KLM000",
            image: "https://placehold.co/300x350/png",
            name: "Sample",
            offer_price: "200",
            price: "599",
            details:"100 ML",
            rating:4,
            ratingBy:10,
            MOQ:2,
            onSale: true
        },
        {
            docId:"E3d8ig2#87434fd",
            sku:"KLM001",
            image: "https://placehold.co/300x350/png",
            name: "Sample",
            offer_price: "200",
            price: "599",
            details:"60 Capsules",
            rating:3,
            ratingBy:8,
            MOQ:2,
            onSale: true
        },
        {
            docId:"E3d8ig2#87433fe",
            sku:"KLM002",
            image: "https://placehold.co/300x350/png",
            name: "Sample",
            offer_price: "200",
            price: "599",
            details:"200 ML",
            rating:0,
            ratingBy:0,
            MOQ:2,
            onSale: true
        },
        {
            docId:"D3d8ig2#87433fd",
            sku:"KLM003",
            image: "https://placehold.co/300x350/png",
            name: "Sample",
            offer_price: "200",
            price: "599",
            details:"10 x 1 x 10 Capsules",
            rating:5,
            ratingBy:5,
            MOQ:1,
            onSale: true
        },
        {
            docId:"EXd8ig2#87433fd",
            sku:"KLM004",
            image: "https://placehold.co/300x350/png",
            name: "Sample",
            offer_price: "200",
            price: "599",
            details:"100 ML",
            rating:4,
            ratingBy:20,
            MOQ:3,
            onSale: true
        }
    ]

    return (
        <>
        <div className='w-4/5 items-center justify-center mx-auto gap-y-5 gap-x-2' style={{ display:"grid", gridTemplateColumns: `repeat(${col},1fr)` }}>
        {array.map((product, key)=>(
            <div key={key} className='flex items-center justify-center'>
                {/* <Link to={`/product/${product.link}`} state={{product:product}}> */}
                <ProductCard product={product} col={col}/>
                {/* </Link> */}
            </div>
        ))}
        </div>
        </>
    )
}

export default ProductsArray
