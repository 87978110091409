import React, { useState } from 'react'
import LoginForm from '../Components/LoginForm'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import SignUpForm from '../Components/SignUpForm';
import Login_Banner from '../Banner/Login Banner.png'

const Auth = () => {
    const [existing, isExisting] = useState(true)
    return (
        <div className='flex items-center justify-center h-[100vh]'>
           <div className='w-[60%] flex flex-col items-center justify-center  h-[max-content]'>
            {existing?<LoginForm/>:<SignUpForm/>}
            <button onClick={()=>isExisting(false)} className='mt-9 text-nowrap'>{existing? <>Don't have an account? Register now <EastOutlinedIcon/></>:null} </button>
           </div>
            <div className='w-0 sm:w-[40%] h-[100%] '>
                {/* <h1 className='text-black text-xl hidden sm:contents'>kolmin</h1> */}
                <img className='w-full h-full object-cover' src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/Serving%20the%20Best%20of%20Ayurveda!.png?alt=media&token=36e24c32-4be9-47c7-b1bc-1cf72a6e1d05" />
            </div>
        </div>
    )
}

export default Auth
