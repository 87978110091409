import React, { useContext, useState } from 'react'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { Link, Outlet } from 'react-router-dom';
import { AppContext } from '../Services/Context/Appcontext';

const Account_sidebar = () => {
    const [name, setName] = useState("My Account");
    const {logUser} = useContext(AppContext)

    console.log(logUser);
    const options = [
        {
          icon: <SpeedOutlinedIcon />,
          name: "DASHBOARD",
          link: "/my_account",
        },
        {
            icon: <ShoppingCartOutlinedIcon />,
            name: "ORDERS",
            link: "/my_account/orders",
        },
        {
            icon: <FmdGoodOutlinedIcon />,
            name: "ADDRESSES",
            link: "/my_account/addresses",
        },
        {
            icon: <PermIdentityOutlinedIcon />,
            name: "ACCOUNT DETAILS",
            link: "/my_account/details",
        },
        {
            icon: <ExitToAppOutlinedIcon />,
            name: "LOG OUT",
            link: "/my_account/log_out",
        },
        
      ];
  return (
<>

    {name === "DASHBOARD" ? <h1 className='text-3xl pl-10 font-bold'>My Account</h1> : <h1 className='text-3xl pl-10 font-bold'>{name}</h1>}
<div className='flex sm:flex-row flex-col gap-8 sm:pl-10 sm:pr-16 px-4 mt-8 '>
    <section className='sm:w-[20%] w-full'>
        <section className='hidden sm:contents'>
        <div className='border-[1px] border-[#DCDBDB] rounded-lg overflow-hidden'>
        {logUser?.role === "admin"?
            
            <Link to="/my_account/admin_panel" onClick={() => setName("Admin panel")} className='flex w-full border-b-[1px] border-[#DCDBDB] font-semibold px-6 py-6 gap-2 focus:bg-green-primary focus:text-white'>
                    <AdminPanelSettingsOutlinedIcon />
                    <p>ADMIN PANEL</p>
                </Link>
            :null}
        {options.map((option, key) => (
            <Link to={option.link} onClick={() => setName(option.name)} key={key} className='flex w-full border-b-[1px] border-[#DCDBDB] font-semibold px-6 py-6 gap-2 focus:bg-green-primary focus:text-white'>
                {option.icon}
                <p>{option.name}</p>
            </Link>

        ))}
        
            {/* <Link to='/my_account/orders' className='flex w-full border-b-[1px] border-[#DCDBDB] font-semibold px-6 py-6 gap-2 focus:bg-green-primary focus:text-white'>
                <ShoppingCartOutlinedIcon />
                <p>ORDERS</p>
            </Link>
            <Link to='/my_account/addresses' className='flex w-full border-b-[1px] border-[#DCDBDB] font-semibold px-6 py-6 gap-2 focus:bg-green-primary focus:text-white'>
                <FmdGoodOutlinedIcon />
                <p>ADDRESSES</p>
            </Link>
            <Link to='/my_account/details' className='flex w-full border-b-[1px] border-[#DCDBDB] font-semibold px-6 py-6 gap-2 focus:bg-green-primary focus:text-white'>
                <PermIdentityOutlinedIcon />
                <p>ACCOUNT DETAILS</p>
            </Link>
            <Link className='flex w-full font-semibold px-6 py-6 gap-2 focus:bg-green-primary focus:text-white'>
                <ExitToAppOutlinedIcon />
                <p>LOG OUT</p>
            </Link> */}
        </div>
        </section>

        <section className='w-full contents sm:hidden'>
            <div className='border-[1px] w-full flex border-[#DCDBDB] rounded-lg overflow-hidden'>
            {options.map((option, key) => (
                <Link to={option.link} onClick={() => setName(option.name)} key={key} className='flex justify-center w-full border-r-[1px] border-[#DCDBDB] font-semibold p-4 focus:bg-green-primary focus:text-white'>
                    {option.icon}
                </Link>

            ))}
            {logUser?.role === "adming"?
            
            <Link to="/my_account/" onClick={() => setName("Admin panel")} className='flex justify-center w-full border-r-[1px] border-[#DCDBDB] font-semibold p-4 focus:bg-green-primary focus:text-white'>
                    <PermIdentityOutlinedIcon />
                </Link>
            :null}
            </div>
        </section>
    </section>

    <section className='sm:w-[80%] w-full'>
      <Outlet/>
    </section>

</div>

</>
  )
}

export default Account_sidebar