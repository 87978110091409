import React, { useContext } from 'react'
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Link } from 'react-router-dom';
import { AppContext } from '../../Services/Context/Appcontext'

const Addresses = () => {

    const {logUser} = useContext(AppContext)

  return (
<>

<div className='w-full'>
    <h1 className='text-2xl font-bold'>My Addresses</h1>
    <p>The following address will be used on the checkout page by default.</p>
    <section className='flex gap-8 mt-10'>
        <div className='w-[50%]'>
            <div className='w-full flex justify-between mb-6'>
                <h1 className='text-2xl font-bold'>Billing Address</h1>
                <Link to="/my_account/billing">
                    <EditNoteIcon /> Edit
                </Link>
            </div>
            <>
            <p>{logUser.firstName} {logUser.lastName}</p>
            <p>{logUser.streetAddress}</p>
            <p>{logUser.city}, {logUser.state}</p>
            <p>{logUser.country}</p>
            <p>{logUser.pin}</p>
            </>
        </div>
        <div className='w-[50%]'>
            <div className='w-full flex justify-between mb-6'>
                <h1 className='text-2xl font-bold'>Shipping Address</h1>
                <button><EditNoteIcon /> Edit</button>
            </div>
            <p>You have not setup this type of address yet.</p>
        </div>
    </section>
</div>

</>
)
}

export default Addresses