import React, { useContext } from "react";
import { AppContext } from "../Services/Context/Appcontext";
import { Link } from "react-router-dom";

const Blogpage = () => {

  const {blogs} = useContext(AppContext)
  console.log(blogs)

  return (
    <>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-20 place-items-center mt-10 sm:w-[80%] w-[90%] mx-auto">
        {blogs?.map((blog,key)=>
        <div className="sm:w-[550px] w-full">
          <img className="sm:h-[320px]" src={blog.thumbnail} />
          <div className="flex gap-x-[10px] items-center text-[#B7B7B7] mt-5">
            <img src="https://placehold.co/16x16" />
            <p> {blog.views} views</p>
            <hr className="h-[15px] w-[2px] bg-[#B7B7B7]" />
            <img src="https://placehold.co/16x16" />
            <p> {blog.publishedOn}</p>
          </div>
          <div className="mt-5">
            <h1 className="font-bold text-sm ">
            {blog.title}
            </h1>
            <p className="text-xs mt-2">
            {blog.short_desc}
            </p>
            <Link to={`/blog/${blog.id}`} state={{blog:blog}}>
            <button className="border-2 border-[#797979] px-10 py-2 rounded-lg font-bold mt-8 text-sm">
              READ MORE
            </button>
            </Link>
          </div>
        </div>
        )}
        
        {/* <div className="sm:w-[550px] w-full">
          <img src="https://placehold.co/550x320" />
          <div className="flex gap-x-[10px] items-center text-[#B7B7B7] mt-5">
            <img src="https://placehold.co/16x16" />
            <p> 322 views</p>
            <hr className="h-[15px] w-[2px] bg-[#B7B7B7]" />
            <img src="https://placehold.co/16x16" />
            <p> January 26,2024</p>
          </div>
          <div className="mt-5">
            <h1 className="font-bold text-sm ">
              Revolutionize Your health With These Easy-peasy Tips
            </h1>
            <p className="text-xs mt-2">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod
              tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrum exercitationem ullam corporis suscipit
            </p>
            <button className="border-2 border-[#797979] px-10 py-2 rounded-lg font-bold mt-8 text-sm">
              READ MORE
            </button>
          </div>
        </div>
        <div className="sm:w-[550px] w-full">
          <img src="https://placehold.co/550x320" />
          <div className="flex gap-x-[10px] items-center text-[#B7B7B7] mt-5">
            <img src="https://placehold.co/16x16" />
            <p> 322 views</p>
            <hr className="h-[15px] w-[2px] bg-[#B7B7B7]" />
            <img src="https://placehold.co/16x16" />
            <p> January 26,2024</p>
          </div>
          <div className="mt-5">
            <h1 className="font-bold text-sm ">
              Revolutionize Your health With These Easy-peasy Tips
            </h1>
            <p className="text-xs mt-2">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod
              tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrum exercitationem ullam corporis suscipit
            </p>
            <button className="border-2 border-[#797979] px-10 py-2 rounded-lg font-bold mt-8 text-sm">
              READ MORE
            </button>
          </div>
        </div>
        <div className="sm:w-[550px] w-full">
          <img src="https://placehold.co/550x320" />
          <div className="flex gap-x-[10px] items-center text-[#B7B7B7] mt-5">
            <img src="https://placehold.co/16x16" />
            <p> 322 views</p>
            <hr className="h-[15px] w-[2px] bg-[#B7B7B7]" />
            <img src="https://placehold.co/16x16" />
            <p> January 26,2024</p>
          </div>
          <div className="mt-5">
            <h1 className="font-bold text-sm ">
              Revolutionize Your health With These Easy-peasy Tips
            </h1>
            <p className="text-xs mt-2">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod
              tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrum exercitationem ullam corporis suscipit
            </p>
            <button className="border-2 border-[#797979] px-10 py-2 rounded-lg font-bold mt-8 text-sm">
              READ MORE
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Blogpage;
