import React from 'react'
import { auth } from '../../Services/firebaseconfig';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

const Log_out = () => {
    
    const navigate = useNavigate()
    function logout() {
        signOut(auth).then(() => {
            navigate("/auth")

        }).catch((error) => {
            console.log(error.message);
    });
    }
  return (
<>

<div className='flex items-center justify-center w-full'>
    <div>
        <h1 className='text-3xl font-bold'>Are you sure you want to log out ?</h1>
        <div className='w-full flex justify-center gap-8 mt-16'>
            <button onClick={() => logout()} className='w-20 py-2 bg-red-400 hover:bg-red-500 text-xl font-semibold rounded-lg'>Yes</button>
            <button className='w-20 py-2 bg-green-400 hover:bg-green-500 text-xl font-semibold rounded-lg'>No</button>
        </div>
    </div>

</div>

</>
)
}

export default Log_out