import React, { useContext } from "react";
import { AppContext } from "../Services/Context/Appcontext";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link } from "react-router-dom";

const TopBar = () => {
  const { userCart, logUser } = useContext(AppContext);

  const subtotal = userCart.products?.reduce((accumulator, product) => {
    return accumulator + parseFloat(Number(product.price) * product.qty);
  }, 0);

  const roundedSubtotal = subtotal ? subtotal.toFixed(2) : "0.00";

  return (
    <>
      <nav className="bg-[#282828] border-gray-200 dark:bg-gray-900 text-white">
        {/* <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4"> */}
        <div className="flex flex-wrap justify-between items-center w-screen p-4 px-8">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/footerlogo.png?alt=media&token=fe9dc616-af1b-46f2-b55f-3ddc2d461236"
              className="h-8"
              alt="Kolmin Logo"
            />
          </a>
          <div className="flex items-center space-x-6 rtl:space-x-reverse">
            <Link to="/wishlist">
            <a
              href="tel:5541251234"
              className="text-sm  text-white hover:underline"
            >
              <FavoriteBorderOutlinedIcon className="mr-1"/>
              Wishlist
            </a>
            </Link>
            <Link to={logUser? '/my_account' : '/auth'}>
            <a
              href="#"
              className="text-sm  text-white dark:text-blue-500 hover:underline"
              >
              <PersonOutlineOutlinedIcon className="mr-1" />
              {logUser?"My Account":"Login"}
            </a>
            </Link>
            <Link to="/cart">
            <a href="">
              <span className=" absolute z-10 bg-green-primary rounded-xl w-[15px] h-[15px] text-center text-xs ml-3 mt-[-3px]">{userCart.products?.length}</span>
              <ShoppingCartOutlinedIcon className="mr-2" />    
              ₹ {roundedSubtotal}
            </a>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default TopBar;
