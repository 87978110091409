// cartReducer.js
import { ADD_TO_CART, INCREASE_QUANTITY, DECREASE_QUANTITY, DELETE_FROM_CART } from './actionTypes';

const initialState = {
  cart: []
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      // Logic for adding to cart
      break;
    case INCREASE_QUANTITY:
      // Logic for increasing quantity
      break;
    case DECREASE_QUANTITY:
      // Logic for decreasing quantity
      break;
    case DELETE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter(item => item.id !== action.payload)
      };
    default:
      return state;
  }
};

export default cartReducer;
