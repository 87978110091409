import React from 'react'

const Shipping_policy = () => {
  return (
<>

<div className='flex flex-col items-center'>

<h1 className='text-2xl font-bold'>Shipping Policy</h1>
<div className='w-full flex flex-col gap-4 mt-4 px-8'>
    <section>
        <p>We are committed to getting your order to you quickly and safely. This policy outlines the shipping options and timelines you can expect when placing an order with Kolmin Healthcare.</p>
    </section>
    <section>
        <h2 className='text-md font-bold'>General Shipping Information:</h2>
        <ul className='ml-6'>
            <li><strong>•	Tracked Shipping on All Orders:</strong> We offer tracked shipping on all orders, regardless of size or value. You will receive a tracking number with your shipment confirmation email, allowing you to monitor the progress of your delivery.</li>
            <li><strong>•	Order Processing:</strong> Orders are typically processed within 1-2 business days.</li>
            <li><strong>•	Shipping Timelines:</strong>Once your order is processed, shipping typically takes: Within India: 3-7 business days
</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>Bulk Order Shipping:</h2>
        <ul className='ml-6'>
            <li>We offer special shipping arrangements for bulk orders. Please contact us directly at kolminhealthcare@gmail.com for a personalized quote on bulk order shipping.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>International Shipping:</h2>
        <ul className='ml-6'>
            <li>We may also disclose your information if required by law.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>Returns:</h2>
        <p className='ml-6'>Please refer to our separate Return and Refund Policy for information on returning your order.</p>
    </section>
    <section>
        <h2 className='text-md font-bold'>Order Tracking:</h2>
        <p className='ml-6'>You can track your order by clicking on the tracking link provided in your shipment confirmation email.</p>
    </section>
    
    <section>
        <h2 className='text-md font-bold'>Contact Us:</h2>
        <p className='ml-6'>If you have any questions about our shipping policy or your order, please don't hesitate to contact us at <a className='text-green-primary' href="mailto:Kolminhealthcare@gmail.com">kolminhealthcare@gmail.com.</a></p>
    </section>
    <section>
        <h2 className='text-md font-bold'>Please Note:</h2>
        <p className='ml-6'>•	Shipping times are estimates and may vary depending on your location and other factors.</p>
        <p className='ml-6'>•	We are not responsible for delays caused by unforeseen circumstances, such as natural disasters or carrier issues.</p>
    </section>
</div>

</div>


</>
  )
}

export default Shipping_policy