import React from 'react'
import { useContext } from 'react'
import { AppContext } from '../../Services/Context/Appcontext'

const Dashboard = () => {
    const {logUser} = useContext(AppContext)
  return (
<>

<p>Hello <b>{logUser.email}</b> (not <b>{logUser.email}</b>? Log Out)</p>
<p className='mt-4'>From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.</p>

</>
  )
}

export default Dashboard