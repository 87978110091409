import React from "react";

const ShopSidebar = ({ products,filteredProducts, setSelectedCategory,setSelectedPrice,selectedPrice,filterProductsByPrice, setFilterProductbyPrice }) => {
  // Initialize an empty array to store unique categories
  const categories = [];

  // Iterate over the products array
  products.forEach((product) => {
    // Check if the category is already in the categories array
    if (!categories.includes(product.category)) {
      // If not, add it to the categories array
      categories.push(product.category);
    }
  });

  return (
    <div className="sm:pl-[50px] sm:px-0 px-4">
      <section className="border-[1px] mt-4 border-[#DCDBDB] rounded-[10px] overflow-hidden">
        <div className="bg-[#EDEDED] pl-5 pr-[150px] py-4 text-xl ">
          <p className="text-xl font-semibold text-left">CATEGORIES</p>
        </div>
        <div>
          <div className="flex items-center ps-4 border-b-[1px] border-[#DCDBDB]">
            <input
              id="All"
              type="radio"
              value="All"
              name="category"
              className="w-4 h-4 text-blue-600 focus:outline-none bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
              onChange={(e) => setSelectedCategory("All")}
            />

            <label
              htmlFor="All"
              className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              All
            </label>
          </div>
          {categories?.map((category, key) => (
            <div
              className="flex items-center ps-4 border-b-[1px] border-[#DCDBDB]"
              key={key}
            >
              <input
                id={category}
                type="radio"
                value={category}
                name="category"
                className="w-4 h-4 text-blue-600 focus:outline-none bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                onChange={(e) => setSelectedCategory(e.target.value)}
              />

              <label
                htmlFor={category}
                className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {category}
              </label>
            </div>
          ))}
        </div>
      </section>
      <section className="border-[1px] mt-4 border-[#DCDBDB] rounded-[10px] overflow-hidden">
        <div className="bg-[#EDEDED] pl-5 pr-[150px] py-4 text-xl ">
          <p className="text-xl font-semibold text-left">CATEGORIES</p>
        </div>
        <div class="relative mb-6 px-3 pt-[30px] flex flex-col gap-5">
          <input
          onChange={(e)=>setSelectedPrice(e.target.value)}
            id="labels-range-input"
            type="range"
            value={selectedPrice}
            min="100"
            max="500"
            class="w-full h-2 bg-gray-200 rounded-lg cursor-pointer dark:bg-gray-700"
          />
          <div className="flex items-center justify-between">
          <p class="text-sm text-gray-500 dark:text-gray-400 start-0 -bottom-6">
          Price: ₹100 — ₹{selectedPrice}
          </p>
          <button onClick={()=>setFilterProductbyPrice(filterProductsByPrice(filteredProducts?filteredProducts:products,100,selectedPrice))} className=" font-semibold border-[#DCDBDB] py-2 px-7 rounded-lg border-[1px]">FILTER</button>
          </div>
          
        </div>
      </section>
    </div>
  );
};

export default ShopSidebar;
