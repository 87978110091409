import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const app = initializeApp({
  apiKey: "AIzaSyD-JLgToiZJJ_tQVkywJgBniTA8DejcmKw",
  authDomain: "kolmin-healthcare.firebaseapp.com",
  projectId: "kolmin-healthcare",
  storageBucket: "kolmin-healthcare.appspot.com",
  messagingSenderId: "17396023971",
  appId: "1:17396023971:web:e12e3827892b7fbc0cb516"
});

const auth = getAuth(app);
const storage = getStorage(app)
const database = getFirestore(app)
const providerGoogle = new GoogleAuthProvider();


export { auth, database, storage, providerGoogle }