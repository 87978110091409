import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { database } from '../../Services/firebaseconfig';

const AddProduct = ({product}) => {

    const [newStock, setNewStock] = useState();

  const updateProduct = (id) => {
    if (newStock) {
      updateDoc(doc(database, "products", id), {
        stock: newStock,
      })
    } else {
      alert("New Stock can't be empty");
    }
  };

    return (
        <div className="flex text-center gap-2">
            <img
              src={product?.image[0]}
              alt=""
              className="w-[500px] h-[450px] contain"
            />
            <div className="flex flex-col items-center w-full justify-between">
              <div className="w-3/4">
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">Product Name*</label>
                  <input
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">Price*</label>
                  <input
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">Offer Price*</label>
                  <input
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">SKU*</label>
                  <input
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">MOQ*</label>
                  <input
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">Category*</label>
                  <input
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="stock">Enter new stock</label>
                  <input
                    type="number"
                    placeholder={product?.stock}
                    onChange={(e) => setNewStock(e.target.value)}
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
              </div>
              <div className="mt-10">
                <h4 className="mb-1 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to update this product?
                </h4>
                <div className="flex justify-center gap-4 ">
                  <button
                    className='bg-green-primary text-white p-2 px-3 rounded-2xl'
                    onClick={() => updateProduct(product.id)}
                  >
                    Yes, I'm sure
                  </button>
                  <button className='text-red-700' >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
    )
}

export default AddProduct
