import emailjs from "emailjs-com";

const SendEmail = (orderData) => {
  const {
    customerName,
    fullAddress,
    contact,
    orderNumber,
    cartProducts,
    subtotal,
    shippingCost,
    total,
    toEmail,
  } = orderData;

  console.log(cartProducts);

  const emailContent = `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Order Confirmation</title>
  <style>
    @media only screen and (max-width: 600px) {
      .container { width: 100% !important; }
      .content { padding: 10px !important; }
      .button { width: 100% !important; display: block !important; }
      .order-details th, .order-details td { display: block !important; width: 100% !important; text-align: left !important; }
    }
    .container { width: 600px; margin: 0 auto; }
    .header { background-color: #f4f4f4; padding: 20px; text-align: center; }
    .header img { max-width: 150px; }
    .content { padding: 20px; background-color: #ffffff; }
    .content h1 { color: #333333; font-family: Arial, sans-serif; font-size: 24px; margin: 0; }
    .content p { color: #666666; font-family: Arial, sans-serif; font-size: 16px; margin: 10px 0; }
    .order-details { width: 100%; margin: 20px 0; border-collapse: collapse; }
    .order-details th, .order-details td { padding: 10px; border: 1px solid #dddddd; font-family: Arial, sans-serif; font-size: 14px; }
    .order-details th { background-color: #f8f8f8; }
    .total { font-weight: bold; }
    .footer { padding: 20px; background-color: #f4f4f4; text-align: center; font-family: Arial, sans-serif; font-size: 12px; color: #aaaaaa; }
    .button-div{display:flex;align-items: center;justify-content: center; margin-top: 15px;}
    .button { background-color: #4F9F1E; color: #ffffff; padding: 10px 20px; text-decoration: none; font-family: Arial, sans-serif; font-size: 16px; border-radius: 5px; text-align: center;}
  </style>
</head>
<body style="margin: 0; padding: 0;">
  <table width="100%" cellpadding="0" cellspacing="0" border="0">
    <tr>
      <td align="center" style="padding: 20px 0;">
        <table class="container" width="600" cellpadding="0" cellspacing="0" border="0" style="border-collapse: collapse;">
          <tr>
            <td class="header">
              <img src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/footerlogo.png?alt=media&token=fe9dc616-af1b-46f2-b55f-3ddc2d461236" alt="Company Logo">
            </td>
          </tr>
          <tr>
            <td class="content">
              <h1>Thank you for your order!</h1>
              <p>Hi ${customerName},</p>
              <p>We're excited to let you know that we've received your order.</p>
              <p><strong>Order Number:</strong> #${orderNumber}</p>
              <table class="order-details">
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
                ${cartProducts
                  .map(
                    (product) => `
                    <tr>
                      <td>${product.name}</td>
                      <td>${product.qty}</td>
                      <td>${Number(product.price) * product.qty}</td>
                    </tr>
                  `
                  )
                  .join("")}
                <tr>
                  <td colspan="2" class="total">Subtotal</td>
                  <td class="total">₹${subtotal}</td>
                </tr>
                <tr>
                  <td colspan="2" class="total">Shipping</td>
                  <td class="total">₹${shippingCost}</td>
                </tr>
                <tr>
                  <td colspan="2" class="total">Total</td>
                  <td class="total">₹${total}</td>
                </tr>
              </table>
              <p>You can track your order status and find more details on your account page.</p>
              <p>Thank you for shopping with us!</p>
              <div class="button-div">
                  <a href="https://kolminhealthcare.com/my_account/orders" class="button">View Order</a>
              </div>
            </td>
          </tr>
          <tr>
            <td class="footer">
              <p>If you have any questions, feel free to <a href="mailto:kolminhealthcare@gmail.com" style="color: #4F9F1E; text-decoration: none;">contact us</a>.</p>
              <p>&copy; 2024 Kolmin Healthcare. All rights reserved.</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
  `;

  const templateParams = {
    order_id: orderNumber,
    message_html: emailContent,
    to_email: toEmail,
  };

  emailjs
    .send(
      "service_rqyoczr",
      "template_hc2lwym",
      templateParams,
      "7t62-bZ9uK1yCnBLo"
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
      },
      (error) => {
        console.error("FAILED...", error);
      }
    );
};

// const OrderConfirmation = () => {
//   const orderData = {
//     customerName: 'John Doe',
//     orderNumber: '12345',
//     products: [
//       { name: 'Product 1', quantity: 1, price: 29.99 },
//       { name: 'Product 2', quantity: 2, price: 19.99 },
//     ],
//     subtotal: 69.97,
//     shippingCost: 5.00,
//     total: 74.97,
//     companyName: 'My Company',
//     year: new Date().getFullYear(),
//   };

//   return (
//     <div>
//       <h1>Order Confirmation</h1>
//       <button onClick={() => sendEmail(orderData)}>Send Email</button>
//     </div>
//   );
// };

export default SendEmail;
