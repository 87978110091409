import React, { useContext } from "react";
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { arrayRemove, arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { AppContext } from "../Services/Context/Appcontext";
import { database } from "../Services/firebaseconfig";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ product }) => {

  const {logUser, userCart} = useContext(AppContext)
  console.log(logUser);

  const navigate = useNavigate()

  function removeField(obj, field) {
  const { [field]: removedField, ...rest } = obj;
  return rest;
}

const increment = (product) =>{
  const newproduct = {...product,qty:product.qty+1}
  console.log(newproduct);
  updateDoc(doc(database,"cart", logUser.uid), {
    products:arrayUnion(newproduct)
  }).then(
    updateDoc(doc(database,"cart", logUser.uid), {
      products:arrayRemove(product)
    })
  )
};

async function addToCart(product) {
  const productWQty = { ...product, qty: product.MOQ };

  if (userCart && userCart.products) {
      const productExists = userCart.products.some(item => item.id === product.id);
      if (productExists) {
          const existingProduct = userCart.products.find(item => item.id === product.id);
          increment(existingProduct);
          return; // Exit function since product is already in the cart
      }
      // If product doesn't exist, add it to the cart
      await setDoc(doc(database, "cart", logUser.uid), {
          products: [...userCart.products, productWQty]
      }, { merge: true });
  } else {
      // If cart data doesn't exist, create a new cart with the product
      await setDoc(doc(database, "cart", logUser.uid), {
          products: [productWQty]
      });
  }
}

  return (
    <>
      <div className="sm:w-[300px] sm:h-[500px] w-[170px] h-[300px]">
        <img className="sm:h-[300px] sm:w-[300px] h-[120px] w-[120px] contain" src={product.image[0]} alt="" />
        <div className="p-2">
        <h1 className="text-sm sm:text-base">{product.name}</h1>
        <p className="sm:text-sm text-xs">({product.details})</p>
        {product.offer_price!==""?<>
        <p className="font-bold">₹{product.offer_price}<span className="line-through font-normal ml-2">₹{product.price}</span></p>
        </> :
        <p className="font-bold text-sm sm:text-base">₹{product.price}</p>
        }
        <p className="flex">
        {Array.from({ length: Number(product.rating) }, (_, index) => (
              <StarOutlinedIcon key={index}/>
            ))}
            {Array.from({ length: 5 - Number(product.rating) }, (_, index) => (
              <StarBorderOutlinedIcon
                key={index + Number(product.rating)}
              />
            ))} <span className="ml-1">({product.ratingBy.length})</span>
        </p>
        </div>
            <button disabled={product.stock == 0} onClick={()=>logUser ? addToCart(product): navigate("/auth")} className="text-center text-white bg-green-primary w-full rounded-xl  uppercase font-bold py-2 mt-4">{product.stock == 0 ?"Out of Stock":"Add to Cart"}</button>
      </div>
    </>
  );
};

export default ProductCard;
