import React from "react";

const Contactus = () => {
  return (
    <>
      <div className="sm:px-[80px] px-4">
        <div className="px-[50px] mt-10">
          <h1 className="text-lg font-medium">Get in Touch</h1>
          <hr className="border-black w-full mt-5" />
        </div>
        <div className="flex justify-center sm:flex-row flex-col sm:gap-[50px] gap-6 mt-10 ">
          <iframe
            className="sm:w-[700px] h-[300px] w-full"
            //id="inlineFrameExample"
            // title="Inline Frame Example"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.937916716133!2d88.42457997537811!3d26.714430276765995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e4416c5137e245%3A0xc2f8db6234fd9e6d!2sTiwari%20Medical%20Stores!5e0!3m2!1sen!2sin!4v1713421483239!5m2!1sen!2sin"
          ></iframe>
          <div className="sm:text-lg text-md">
            <p>
              Address : Tilak Road, Opp.Kanchanjunga Stadium <br /> Gate No.5,
              Siliguri 734001
            </p>
            <p>Phone No : +91 9932042529</p>
            <p>Email : kolminhealthcare@gmail.com</p>
          </div>
        </div>

        <div className="sm:px-[50px] mt-10">
          <h1 className="text-lg font-medium">Have Queries? Write to us.</h1>
          <hr className="border-black w-full mt-5" />
        </div>


        
          <form className=" flex flex-col w-full sm:px-12">
            <div className="flex sm:flex-row flex-col gap-x-[50px] mt-10 w-full">
            <label className="flex flex-col w-full sm:w-[50%]">
              <span className=" text-sm font-medium text-black">
               First Name*
              </span>
              <input
                type="text"
                value=""
                disabled
                className="w-[100%] rounded-xl bg-transparent border-[1px] border-[#A0A0A0] pl-2 py-1 mt-2"
              />
            </label>
            <label className="flex flex-col w-full sm:w-[50%] sm:mt-0 mt-[32px]">
              <span className=" text-sm font-medium text-black">
               Middle Name
              </span>
              <input
                type="text"
                value=""
                disabled
                className="w-[100%] rounded-xl bg-transparent border-[1px] border-[#A0A0A0] pl-2 py-1 mt-2"
              />
            </label>
            </div>
            <label className="block w-full sm:w-[48%] mt-8 ">
              <span className="block text-sm font-medium text-black">
               Last Name*
              </span>
              <input
                type="text"
                value=""
                disabled
                className="w-[100%] rounded-xl bg-transparent border-[1px] border-[#A0A0A0] pl-2 py-1 mt-2"
              />
            </label>
            <div className="flex flex-col sm:flex-row gap-x-[50px] mt-8 ">
            <label className="flex flex-col w-full sm:w-[50%] ">
              <span className=" text-sm font-medium text-black">
               Phone number*
              </span>
              <input
                type="text"
                value=""
                disabled
                className="w-[100%] rounded-xl bg-transparent border-[1px] border-[#A0A0A0] pl-2 py-1 mt-2"
              />
            </label>
            <label className="flex flex-col w-full sm:w-[50%] sm:mt-0 mt-[32px]">
              <span className=" text-sm font-medium text-black">
               Email address*
              </span>
              <input
                type="text"
                value=""
                disabled
                className="w-[100%] rounded-xl bg-transparent border-[1px] border-[#A0A0A0] pl-2 py-1 mt-2"
              />
            </label>
            </div>
            <div className="flex justify-center items-center mt-8">
              <button className=" bg-green-primary text-white text-lg px-6 py-2 rounded-md">Submit</button>
            </div>
          </form>
          
        
      </div>
    </>
  );
};

export default Contactus;
