import React, { useEffect, useState } from 'react'
import Nav from './Components/Nav'
import { Outlet } from 'react-router-dom'
import Footer from "./Components/Footer";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Layout = () => {

const [isVisible, setIsVisible] = useState(false);

const handleScroll = () => {
const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
const scrollHeight = document.documentElement.scrollHeight;
const clientHeight = document.documentElement.clientHeight;

if (scrollTop > (scrollHeight - clientHeight) / 5) {
    setIsVisible(true);
} else {
    setIsVisible(false);
}
};

useEffect(() => {
window.addEventListener('scroll', handleScroll);
return () => {
    window.removeEventListener('scroll', handleScroll);
};
}, []);


const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // This makes the scroll smooth
    });
    }

    return (
        <>
        <Nav/>
        <Outlet/>
        {isVisible ? <button onClick={() => handleScrollToTop()} className='fixed bottom-[5%] right-[2%] rounded-full bg-gray-200 hover:bg-green-primary p-2'>< ArrowUpwardIcon fontSize='large'/></button> : null }
        <Footer/>
        </>
    )
}

export default Layout
