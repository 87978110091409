import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Services/Context/Appcontext";
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import { doc, updateDoc } from "firebase/firestore";
import { database } from "../../Services/firebaseconfig";

const StockManagement = () => {
  const { products } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [thisProduct, setThisProduct] = useState();
  const [productCount, setProductCount] = useState(3);
  const [updating, setUpdating] = useState({id:''})
  console.log(productCount, products.length);

  const [newStock, setNewStock] = useState();

  const updateProduct = (id) => {
    if (newStock) {
      updateDoc(doc(database, "products", id), {
        stock: newStock,
      }).then(window.location.reload())
    } else {
      alert("New Stock can't be empty");
    }
  };

  return (
    <>
      <h1 className="text-black font-bold text-3xl mb-4">Manage Stocks</h1>
      <section className="w-full border-[1px] border-[#DCDBDB] rounded-lg">
        <div className="flex justify-between py-2 px-10 text-md font-semibold border-b-[1px] border-[#DCDBDB]">
          <p className="min-w-[100px] text-center">Image</p>
          <p className="min-w-[100px] text-center">Name</p>
          <p className="min-w-[100px] text-center">Current Stock</p>
          <p className="min-w-[100px] text-center ">Action</p>
          {/* <p className="min-w-[100px] text-center">Actions</p> */}
        </div>
        {products?.map((product) => (
          <>
                <div className="flex justify-between py-6 px-10 items-center text-md border-b-[1px] border-[#DCDBDB]">
                  <img className="w-[100px]" src={product.image[0]} alt="" />
                  <p className="min-w-[100px] max-w-[100px] text-center text-wrap">
                    {product.name}
                  </p>
                  <p className="min-w-[100px] max-w-[100px] text-center text-wrap">
                    {product.stock}
                  </p>
                  {updating.id === product.sku? 
                  
                  <div className="flex flex-col text-left items-center">
                  <label htmlFor="stock">Enter new stock</label>
                  <input
                    type="number"
                    placeholder={product?.stock}
                    onChange={(e) => setNewStock(e.target.value)}
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1 w-[100px]"
                  />
                  <button className="bg-green-primary hover:bg-green-500 mt-1 text-white rounded-2xl w-[90px] py-1" onClick={()=>{updateProduct(product.id); setUpdating({...updating, id: ''})}}>Confirm</button>
                  <button className="bg-red-500 hover:bg-red-700 mt-1 text-white rounded-2xl w-[90px] py-1" onClick={()=> setUpdating({...updating, id: ''})}>Cancel</button>
                </div>
                  :
                  
                  <button onClick={()=>setUpdating({...updating, id: product.sku})} className="min-w-[100px] max-w-[100px] text-center text-wrap bg-green-primary text-white hover:bg-green-500 py-2 rounded-2xl">
                    Update
                  </button>}
                  
                  {/* <Link
                    to={`/my_account/order/${order[1].orderId}`}
                    state={{ orderItem: order[1] }}
                    className="px-8 py-2 bg-green-primary rounded-lg text-white font-semibold"
                  >
                    View
                  </Link> */}
                </div>
          </>
        ))}
      </section>
    </>
  );
};

export default StockManagement;
