import React, { useContext, useState } from "react";
import ProductsArray from "../Components/ProductsArray";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import banner from "../Banner/Web Banner - 1.png";
import Carousel from "../Components/Carousel";
import { AppContext } from "../Services/Context/Appcontext";
import ProductCard from "../Components/ProductCard";
import { useRef } from "react";

const Homepage = () => {
  const [bestseller, setBestseller] = useState(true);
  const [featured, setFeatured] = useState(false);
  const [toprated, setToprated] = useState(false);
  const [index, setIndex] = useState(0)

  function open_bestseller() {
    setBestseller(true);
    setFeatured(false);
    setToprated(false);
  }
  function open_featured() {
    setBestseller(false);
    setFeatured(true);
    setToprated(false);
  }
  function open_toprated() {
    setBestseller(false);
    setFeatured(false);
    setToprated(true);
  }
  const containerRef = useRef(null);
  const scrollRight = (percentage) => {
    if (containerRef.current) {
    //   const scrollAmount =
    //     containerRef.current.clientWidth * (percentage / 100);
    //   containerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    setIndex((nextIndex) =>
      nextIndex + 4 < products.length ? nextIndex + 4 : 0
  );
  }
  };

  const scrollLeft = (percentage) => {
    if (containerRef.current) {
      // const scrollAmount =
      //   containerRef.current.clientWidth * (percentage / 100);
      // containerRef.current.scrollBy({
      //   left: -scrollAmount,
      //   behavior: "smooth",
      // });
      setIndex((nextIndex) =>
        nextIndex - 4 >= 0 ? nextIndex - 4 : products.length - (products.length % 4)
    );
    }
  };
  const { products } = useContext(AppContext);

  return (
    <>
      <div className="sm:h-[628px] h-[150px]">
        <Carousel images={[banner, banner]} />
      </div>

      {/* <div className='flex justify-center mt-10 relative '>
      <ArrowCircleLeftOutlinedIcon fontSize='large'/>
        <img className='' src='https://placehold.co/1200x400'/>
    <ArrowCircleRightOutlinedIcon fontSize='large'/>
    </div> */}
      <div className="flex  items-center gap-20 mt-20 sm:justify-around sm:gap-0 flex-col sm:flex-row ">
        <div className=" flex flex-col items-center w-[180px] text-center gap-y-[5px]">
          <img className="rounded-full" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/100%25%20Ayurvedic.png?alt=media&token=afa01bce-46da-4ab9-96c6-576de6a06c8e" />
          <h1 className="text-xs font-bold">100% AYURVEDIC</h1>
          <p className=" text-sm">
            100% Ayurvedic and Effective treatment for your body.
          </p>
        </div>
        <div className=" flex flex-col items-center w-[180px] text-center gap-y-[5px]">
          <img className="rounded-full" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/GMP%20Certification.png?alt=media&token=7fe588b9-b70f-4888-9a1d-0868790dba88" />
          <h1 className="text-xs font-bold">GMP CERTIFIED</h1>
          <p className="text-sm">
            Manufactured under GMP certified Facilities.
          </p>
        </div>
        <div className=" flex flex-col items-center w-[180px] text-center gap-y-[5px]">
          <img className="rounded-full" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/Made%20in%20India.png?alt=media&token=e8cb757b-b4ef-493c-9181-ec839700d476" />
          <h1 className="text-xs font-bold">MADE IN INDIA</h1>
          <p className="text-sm">Proudly made in India for the World.</p>
        </div>
      </div>

      <div className="flex sm:justify-around px-4 sm:px-0 items-center gap-5 sm:gap-0 mt-20 flex-col sm:flex-row">
        <img className="" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/Diabetes.png?alt=media&token=82b6c86a-87d6-4633-9708-f55ecdafa589" />
        <img className="" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/Skin%20Problems.png?alt=media&token=d8a9f3db-9611-445f-ac2f-15d4d6cab07b" />
        <img className="" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/Joint%20Pain.png?alt=media&token=95b3189f-1d50-44b9-bc51-c5d5980371dd" />
      </div>
      <div className="text-center mt-10">
        <h1 className="font-bold text-xl">Our Products</h1>
      </div>

      {/* <div className="flex justify-center flex-wrap gap-x-5 mt-10">
        <div className={`sm:text-xl text-lg font-medium ${bestseller ? `bg-[#4F9F1E] text-white border-2` : `border-[#ddd] border-2 text-black`} sm:w-[180px] w-[150px] sm:mt-0 mt-2 h-[50px] sm:rounded-[20px] rounded-[10px] justify-center flex `}>
          <button onClick={() => open_bestseller()}>BESTSELLER</button>
        </div>
        <div className={`sm:text-xl text-lg font-medium ${featured ? `bg-[#4F9F1E] text-white border-2` : `border-[#ddd] border-2 text-black`} sm:w-[180px] w-[150px] sm:mt-0 mt-2 h-[50px] sm:rounded-[20px] rounded-[10px] justify-center flex `}>
          <button onClick={() => open_featured()}>FEATURED</button>
        </div>
        <div className={`sm:text-xl text-lg font-medium ${toprated ? `bg-[#4F9F1E] text-white border-2` : `border-[#ddd] border-2 text-black`} sm:w-[180px] w-[150px] sm:mt-0 mt-2 h-[50px] sm:rounded-[20px] rounded-[10px] justify-center flex `}>
          <button onClick={() => open_toprated()}>TOP RATED</button>
        </div>
      </div> */}

      <div className=" mt-10 relative hover-show ">
        <button
          className="absolute top-[45%] sm:left-10 left-0"
          onClick={() => scrollLeft(100)}
        >
          <ArrowCircleLeftOutlinedIcon fontSize="large" />
        </button>
        <div
          ref={containerRef}
          className="flex sm:justify-center sm:gap-x-[40px] gap-x-[10px] overflow-hidden scrollbar-w-none sm:px-20  px-4 "
        >
          {bestseller ? (
            <>
            <div className="sm:contents hidden">
              {products.slice(index, index+4).map((product, key) => (
                <div className="sm:min-w-[300px]">
                  <ProductCard product={product} />
                </div>
              ))}
            </div>
            <div className="sm:hidden contents">
              {products.map((product, key) => (
                <div className="sm:min-w-[300px]">
                  <ProductCard product={product} />
                </div>
              ))}
            </div>
            </>
          ) : (
            <></>
          )}
          {featured ? (
            <>
              {products.map((product, key) => (
                <div className="sm:min-w-[300px]">
                  <ProductCard product={product} />
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
          {toprated ? (
            <>
              {products.map((product, key) => (
                <div className="sm:min-w-[300px]">
                  <ProductCard product={product} />
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
        {/* {featured ? 
      <div className='flex gap-x-[50px]  scrollbar-w-none min-w-[500px]'>
      {products.map((product,key)=>(     
        <ProductCard className='' product={product}/>
      ))}
      </div>
      :<></>
      }
      {toprated ? 
      <div className='flex gap-x-[50px]  scrollbar-w-none min-w-[500px]'>
      {products.map((product,key)=>(     
        <ProductCard className='' product={product}/>
      ))}
      </div>
      :<></>
      } */}
        <button
          className="absolute top-[45%] sm:right-10 right-0"
          onClick={() => scrollRight(100)}
        >
          <ArrowCircleRightOutlinedIcon fontSize="large" />
        </button>
      </div>
    </>
  );
};

export default Homepage;
