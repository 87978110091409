import React, { useState } from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, database } from "../Services/firebaseconfig";
import { useNavigate } from "react-router-dom";
import { addDoc, doc, setDoc } from "firebase/firestore";

const SignUpForm = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmPass: "",
  });
  const [err, setErr] = useState("");

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const isValidEmail = emailRegex.test(user.email);

  const [isPasswordVisible, setIsPasswordVisible] = useState({
    pass: false,
    confirm: false,
  });

  function togglePasswordVisibility(field) {
    setIsPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  }

  function register() {
    if (isValidEmail === true && user.email !== "") {
      setErr("");
      if (user.password.length < 7) {
        setErr("Password should be minimum 8 characters");
      } else {
        if (user.password !== user.confirmPass) {
          setErr("Confirm password doesn't match");
        } else {
          setErr("");
          const {email} = user
          console.log(email);
          createUserWithEmailAndPassword(auth, user.email, user.password).then(
            (user) => {
              setDoc(doc(database, "users", user.user.uid), {
                companyName: "",
                country: "",
                email: email,
                firstName: "",
                lastName: "",
                phone: "",
                pin: "",
                state: "",
                streetAddress: "",
                town: "",
              });
            }
          ).then(navigate("/shop"))
        }
      }
    } else {
      setErr("Enter valid email");
    }
  }

  //   setTimeout(()=>{setErr('')}, 5000)

  return (
    <div className="flex flex-col items-center justify-center w-[310px]">
      <h1 className="text-2xl">Login to your account</h1>
      <form action="submit" className="flex flex-col pt-16 gap-6">
        <label htmlFor="email">Enter e-mail</label>
        <input
          type="email"
          name="email"
          id="email"
          className="w-[310px] rounded-xl bg-transparent border-[1px] border-black pl-2 py-1"
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
        <label htmlFor="passwor">Create password</label>
        <div className="relative w-full">
          <input
            type={isPasswordVisible.pass ? "text" : "password"}
            placeholder="Password"
            className="w-[310px] rounded-xl bg-transparent border-[1px] border-black pl-2 py-1"
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
          <p
            className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
            onClick={() => togglePasswordVisibility("pass")}
          >
            {!isPasswordVisible.pass ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
          </p>
        </div>
        <label htmlFor="passwor">Confirm password</label>
        <div className="relative w-full">
          <input
            type={isPasswordVisible.confirm ? "text" : "password"}
            placeholder="Password"
            className="w-[310px] rounded-xl bg-transparent border-[1px] border-black pl-2 py-1"
            onChange={(e) => setUser({ ...user, confirmPass: e.target.value })}
          />
          <p
            className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
            onClick={() => togglePasswordVisibility("confirm")}
          >
            {!isPasswordVisible.confirm ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
          </p>
        </div>
      </form>
      {err != "" ? <p>{err}</p> : null}
      <div className="text-center">
        <button
          onClick={() => register()}
          className="py-2 px-4 rounded-full mt-8 bg-green-primary text-white"
        >
          Register
          <EastOutlinedIcon />
        </button>
      </div>
    </div>
  );
};

export default SignUpForm;
