import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../Services/Context/Appcontext'

const Orders = () => {

    const {orders} = useContext(AppContext)

    console.log(orders.orders?.reverse());

  return (
<>

<section className='w-full border-[1px] border-[#DCDBDB] rounded-lg'>
    <div className='flex justify-between py-2 px-10 text-md font-semibold border-b-[1px] border-[#DCDBDB]'>
        <p className='min-w-[100px] text-center'>Order</p>
        <p className='min-w-[100px] text-center'>Date</p>
        <p className='min-w-[100px] text-center'>Status</p>
        <p className='min-w-[100px] text-center'>Total</p>
        <p className='min-w-[100px] text-center'>Actions</p>
    </div> 
    {orders.orders?.map((order)=>(
    <div className='flex justify-between py-6 px-10 items-center text-md border-b-[1px] border-[#DCDBDB]'>
        <p className='min-w-[100px] max-w-[100px] text-center text-wrap'>#{order.orderId}</p>
        <p className='min-w-[100px] max-w-[100px] text-center text-wrap'>{order.date}</p>
        <p className='min-w-[100px] max-w-[100px] text-center text-wrap'>{order.status}</p>
        <p className='min-w-[100px] max-w-[100px] text-center text-wrap'>Rs {order.subtotal}</p>
        <Link to={`/my_account/order/${order.orderId}`} state={{orderItem:order}} className='px-8 py-2 bg-green-primary rounded-lg text-white font-semibold'>
            View
        </Link>
    </div>  
    ))} 

      
</section>

</>
)
}

export default Orders