import React, { useRef, useState } from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useLocation } from "react-router-dom";

const BlogPage_2 = () => {

  const {state} = useLocation()

  const [blog, setBlog] = useState(state.blog)

  console.log(blog)

  const socialLinks = [
    {
      icon: <GoogleIcon />,
      link: "https://www.google.com",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com",
    },
    {
      icon: <InstagramIcon />,
      link: "",
    },
    {
      icon: <FacebookOutlinedIcon />,
      link: "#",
    },
    {
      icon: <XIcon />,
      link: "#",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com",
    },
  ];
  const containerRef = useRef(null);

  const scrollRight = (percentage) => {
    if (containerRef.current) {
      const scrollAmount =
        containerRef.current.clientWidth * (percentage / 100);
      containerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const scrollLeft = (percentage) => {
    if (containerRef.current) {
      const scrollAmount =
        containerRef.current.clientWidth * (percentage / 100);
      containerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="px-[4%]">
        <button>
          <KeyboardBackspaceOutlinedIcon fontSize="large" />
        </button>

        <div className="flex sm:flex-row flex-col gap-8 mt-10">
          <section className="w-full sm:w-[75%]">
            <div>
              <h1 className="text-3xl font-semibold">
              {blog.title}
              </h1>
              <div className="flex items-center mt-4 text-[#949494]">
                <AccessTimeOutlinedIcon fontSize="small" />
                <p>{blog.publishedOn}</p>
                {/* <hr className="h-[20px] border-[1px] border-[#949494] ml-4 mr-[2px]" />
                <ChatBubbleOutlineRoundedIcon fontSize="small" />
                <p>100 comments</p>
                <hr className="h-[20px] border-[1px] border-[#949494] ml-4 mr-[2px]" />
                <FolderOutlinedIcon fontSize="small" />
                <p>Posted in Curcumin,Elderberry,fermentum,Licorice Root</p> */}
              </div>

              <hr className="w-full mt-4 border-2 rounded-full" />

              <div className="w-full mt-6">
                <p>
                  {blog.short_desc}
                </p>
                <img
                  src={blog.main_banner}
                  className="w-full mt-6 mb-6"
                />


                  <ol>
                      {blog.descriptions?.map((paras, key) => (
                        <li className="flex mt-4">
                          <strong>{key+1}.</strong>
                          <div className="ml-2">
                            <strong>{paras.desc_heading}</strong>
                            <p className="mt-2">{paras.desc_content}</p>
                          </div>
                        </li>
                      ))} 
                  </ol>
                  <p className="mt-4">{blog.desc_p1}</p>
                  <p className="mt-4">{blog.desc_p2}</p>

                {/*<p className="mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  id elementum dolor, vel hendrerit tellus. Cras neque quam,
                  hendrerit ut pellentesque non, convallis consequat massa.
                  Aenean malesuada scelerisque lacus, in maximus neque placerat
                  id. Duis maximus tellus at sapien dignissim mattis. Nam ante
                  nibh, facilisis et diam ut, consectetur lobortis ante. Aliquam
                  quis velit facilisis dui lacinia lacinia. Vivamus ultricies
                  diam tellus, maximus vehicula arcu mattis non. Nullam auctor
                  elit non risus semper auctor. In hac habitasse platea
                  dictumst. Aenean non tincidunt quam, sed elementum urna. Fusce
                  vel posuere arcu. Proin et turpis quis nisi vulputate
                  vulputate sed quis magna. Etiam et pharetra quam, ac gravida
                  risus.
                </p>
                 <p className="mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  id elementum dolor, vel hendrerit tellus. Cras neque quam,
                  hendrerit ut pellentesque non, convallis consequat massa.
                  Aenean malesuada scelerisque lacus, in maximus neque placerat
                  id. Duis maximus tellus at sapien dignissim mattis. Nam ante
                  nibh, facilisis et diam ut, consectetur lobortis ante. Aliquam
                  quis velit facilisis dui lacinia lacinia. Vivamus ultricies
                  diam tellus, maximus vehicula arcu mattis non. Nullam auctor
                  elit non risus semper auctor. In hac habitasse platea
                  dictumst. Aenean non tincidunt quam, sed elementum urna. Fusce
                  vel posuere arcu. Proin et turpis quis nisi vulputate
                  vulputate sed quis magna. Etiam et pharetra quam, ac gravida
                  risus.
                </p>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  id elementum dolor, vel hendrerit tellus. Cras neque quam,
                  hendrerit ut pellentesque non, convallis consequat massa.
                  Aenean malesuada scelerisque lacus, in maximus neque placerat
                  id. Duis maximus tellus at sapien dignissim mattis. Nam ante
                  nibh, facilisis et diam ut, consectetur lobortis ante. Aliquam
                  quis velit facilisis dui lacinia lacinia. Vivamus ultricies
                  diam tellus, maximus vehicula arcu mattis non. Nullam auctor
                  elit non risus semper auctor. In hac habitasse platea
                  dictumst. Aenean non tincidunt quam, sed elementum urna. Fusce
                  vel posuere arcu. Proin et turpis quis nisi vulputate
                  vulputate sed quis magna. Etiam et pharetra quam, ac gravida
                  risus.
                </p> */}
              </div>

              <hr className="w-full mt-6 mb-4 border-2 rounded-full" />

              <button className="text-xs text-[#949494]">PREVIOUS</button>
              <p className="mt-2">10 Warning Signs Of Your health Demise</p>

              <section className="border-[1px] border-[#DCDBDB] rounded-[10px] overflow-hidden mt-6">
                <div className="flex bg-[#EDEDED] px-[20px] py-4 text-xl ">
                  <p className="text-xl text-green-primary font-semibold mr-auto">
                    RELATED POST
                  </p>
                  <button onClick={() => scrollLeft(100)}>
                    <ChevronLeftOutlinedIcon className="mr-4" />
                  </button>
                  <button onClick={() => scrollRight(100)}>
                    <ChevronRightOutlinedIcon />
                  </button>
                </div>

                <section
                  className="flex gap-10 p-6 w-full overflow-scroll scrollbar-w-none"
                  ref={containerRef}
                >
                  <div className="flex min-w-[300px] gap-4 max-h-[70px] snap-center">
                    <img src="https://placehold.co/100x70" />
                    <div>
                      <p>10 Warning Signs Of Your health Demise</p>
                      <p className="flex items-center gap-2 text-[#B7B7B7]">
                        <PeopleAltOutlinedIcon fontSize="small" /> 332 views
                      </p>
                    </div>
                  </div>
                  <div className="flex min-w-[300px] gap-4 max-h-[70px] snap-center">
                    <img src="https://placehold.co/100x70" />
                    <div>
                      <p>10 Warning Signs Of Your health Demise</p>
                      <p className="flex items-center gap-2 text-[#B7B7B7]">
                        <PeopleAltOutlinedIcon fontSize="small" /> 332 views
                      </p>
                    </div>
                  </div>
                  <div className="flex min-w-[300px] gap-4 max-h-[70px] snap-center">
                    <img src="https://placehold.co/100x70" />
                    <div>
                      <p>10 Warning Signs Of Your health Demise</p>
                      <p className="flex items-center gap-2 text-[#B7B7B7]">
                        <PeopleAltOutlinedIcon fontSize="small" /> 332 views
                      </p>
                    </div>
                  </div>
                  <div className="flex min-w-[300px] gap-4 max-h-[70px] snap-center">
                    <img src="https://placehold.co/100x70" />
                    <div>
                      <p>10 Warning Signs Of Your health Demise</p>
                      <p className="flex items-center gap-2 text-[#B7B7B7]">
                        <PeopleAltOutlinedIcon fontSize="small" /> 332 views
                      </p>
                    </div>
                  </div>
                  <div className="flex min-w-[300px] gap-4 max-h-[70px] snap-center">
                    <img src="https://placehold.co/100x70" />
                    <div>
                      <p>10 Warning Signs Of Your health Demise</p>
                      <p className="flex items-center gap-2 text-[#B7B7B7]">
                        <PeopleAltOutlinedIcon fontSize="small" /> 332 views
                      </p>
                    </div>
                  </div>
                  <div className="flex min-w-[300px] gap-4 max-h-[70px] snap-center">
                    <img src="https://placehold.co/100x70" />
                    <div>
                      <p>10 Warning Signs Of Your health Demise</p>
                      <p className="flex items-center gap-2 text-[#B7B7B7]">
                        <PeopleAltOutlinedIcon fontSize="small" /> 332 views
                      </p>
                    </div>
                  </div>
                </section>
              </section>

              <p className="text-xl font-bold mt-4">Leave a Comment</p>
              <section className="mt-2">
                <p className="text-xl font-semibold mb-2">
                  Login with your Social ID
                </p>
                <div className="flex gap-4">
                  {socialLinks.map((link, key) => (
                    <a
                      href={link.link}
                      target="_blank"
                      className="text-gray-600"
                    >
                      {link.icon}
                    </a>
                  ))}
                </div>
              </section>
              <textarea
                id="comment"
                name="comment"
                placeholder="Comment"
                rows="3"
                class="block mt-4 w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-300 sm:text-md sm:leading-6"
              ></textarea>
              <input
                placeholder="Name"
                name=""
                type="text"
                className="block mt-4 w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-300 sm:text-md sm:leading-6k"
              />
              <input
                placeholder="Email"
                name=""
                type="email"
                className="block mt-4 w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-300 sm:text-md sm:leading-6"
              />
              <input
                placeholder="Website"
                name=""
                type="text"
                className="block mt-4 w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-300 sm:text-md sm:leading-6"
              />
              <div className="flex gap-2 mt-4 items-center w-full">
                <input
                  type="checkbox"
                  name="remember-me"
                  id="remember-me"
                  className="bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1 focus:ring-0"
                />
                <label htmlFor="remember-me">
                  Save my name, email, and website in this browser for the next
                  time I comment.
                </label>
              </div>
              <div className="w-full flex justify-center">
                <button className="bg-green-primary w-[30%] mt-12 rounded-md text-white text-xl font-semibold">
                  Submit
                </button>
              </div>
            </div>
          </section>

          <div className="w-full sm:w-[25%]">
            {/* Right Side */}
            <section className="border-[1px] border-[#DCDBDB] rounded-[10px] overflow-hidden">
              <div className="bg-[#EDEDED] pl-[20px] py-4 text-xl ">
                <p className="text-xl font-semibold">SEARCH POST</p>
              </div>
              <div className="w-full flex justify-center">
                <input
                  type="text"
                  name="search post"
                  id="search-post"
                  placeholder="Search"
                  className=" w-[90%] my-4 rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                />
              </div>
            </section>

            <section className="border-[1px] mt-4 border-[#DCDBDB] rounded-[10px] overflow-hidden">
              <div className="bg-[#EDEDED] pl-[20px] py-4 text-xl ">
                <p className="text-xl font-semibold">BLOG CATEGORIES</p>
              </div>
              <div>
                <div className="flex items-center ps-4 border-b-[1px] border-[#DCDBDB] ">
                  <input
                    id="Capsules(3)"
                    type="radio"
                    value=""
                    name="Capsules(3)"
                    className="w-4 h-4 text-green-primary focus:outline-none focus:ring-0 bg-gray-100 border-gray-300 "
                  />

                  <label
                    htmlFor="Capsules(3)"
                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Capsules (3)
                  </label>
                </div>
                <div className="flex items-center ps-4 border-b-[1px] border-[#DCDBDB] ">
                  <input
                    id="Cream(1)"
                    type="radio"
                    value=""
                    name="Cream(1)"
                    className="w-4 h-4 text-green-primary focus:outline-none focus:ring-0  bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                  />

                  <label
                    htmlFor="Cream(1)"
                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Cream (1)
                  </label>
                </div>
              </div>
            </section>

            <section className="border-[1px] mt-4 border-[#DCDBDB] rounded-[10px] overflow-hidden">
              <div className="bg-[#EDEDED] pl-[20px] py-4 text-xl ">
                <p className="text-xl font-semibold">MOST VIEWED</p>
              </div>
              <section className="px-4 pt-4">
                <div className="flex gap-4 max-h-[70px] mb-4">
                  <img src="https://placehold.co/100x70" />
                  <div>
                    <p>10 Warning Signs Of Your health Demise</p>
                    <p className="flex items-center gap-2 text-[#B7B7B7]">
                      <PeopleAltOutlinedIcon fontSize="small" /> 332 views
                    </p>
                  </div>
                </div>
                <div className="flex gap-4 max-h-[70px] mb-4">
                  <img src="https://placehold.co/100x70" />
                  <div>
                    <p>10 Warning Signs Of Your health Demise</p>
                    <p className="flex items-center gap-2 text-[#B7B7B7]">
                      <PeopleAltOutlinedIcon fontSize="small" /> 332 views
                    </p>
                  </div>
                </div>
                <div className="flex gap-4 max-h-[70px] mb-4">
                  <img src="https://placehold.co/100x70" />
                  <div>
                    <p>10 Warning Signs Of Your health Demise</p>
                    <p className="flex items-center gap-2 text-[#B7B7B7]">
                      <PeopleAltOutlinedIcon fontSize="small" /> 332 views
                    </p>
                  </div>
                </div>
              </section>
            </section>

            <section className="border-[1px] mt-4 border-[#DCDBDB] rounded-[10px] overflow-hidden text-wrap">
              <div className="bg-[#EDEDED] pl-[20px] py-4 text-xl ">
                <p className="text-xl font-semibold">TRENDING TAGS</p>
              </div>
              <p className="px-6 py-4 ">
                #bloodsugar,#diabetes #capsules #fistula #health
                #healthylifestyle #holistichealth #immunity #ksharsutra
                #leucorrhoea #love
              </p>
            </section>
          </div>

          {/* <section className='w-[25%] bg-red-300'>
                

            </section> */}
        </div>
      </div>
    </>
  );
};

export default BlogPage_2;
