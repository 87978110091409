import React from 'react'

const Privacy_Policy = () => {
  return (
<>

<div className='flex flex-col items-center'>

<h1 className='text-2xl font-bold'>Privacy Policy</h1>
<div className='w-full flex flex-col gap-4 mt-4 px-8'>
    <section>
        <h2 className='text-md font-bold'>1. Introduction</h2>
        <p className='ml-6'>Kolmin Healthcare is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information.</p>
    </section>
    <section>
        <h2 className='text-md font-bold'>2. Information We Collect</h2>
        <ul className='ml-6'>
            <li><strong>Personal Information:</strong> Name, email address, phone number, and payment information.</li>
            <li><strong>Non-Personal Information:</strong> Browser type, IP address, and usage data.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>3. How We Use Your Information</h2>
        <ul className='ml-6'>
            <li>To process and fulfill your orders.</li>
            <li>To communicate with you about your orders and our services.</li>
            <li>To improve our website and services.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>4. Sharing Your Information</h2>
        <ul className='ml-6'>
            <li>We do not sell, trade, or otherwise transfer your personal information to outside parties except as necessary to fulfill your order (e.g., shipping companies).</li>
            <li>We may also disclose your information if required by law.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>5. Security</h2>
        <p className='ml-6'>We implement a variety of security measures to protect your personal information.</p>
    </section>
    <section>
        <h2 className='text-md font-bold'>6. Cookies</h2>
        <p className='ml-6'>Our website uses cookies to enhance your experience. You can choose to disable cookies through your browser settings.</p>
    </section>
    <section>
        <h2 className='text-md font-bold'>7. Your Rights</h2>
        <ul className='ml-6'>
            <li>You have the right to access, correct, or delete your personal information.</li>
            <li>To exercise these rights, please contact us at <a className='text-green-primary' href="mailto:Kolminhealthcare@gmail.com">Kolminhealthcare@gmail.com</a>.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>8. Changes to this Privacy Policy</h2>
        <p className='ml-6'>We may update this Privacy Policy from time to time. Any changes will be posted on this page.</p>
    </section>
    <section>
        <h2 className='text-md font-bold'>9. Contact Information</h2>
        <p className='ml-6'>For any questions or concerns, please contact us at <a className='text-green-primary' href="mailto:Kolminhealthcare@gmail.com">Kolminhealthcare@gmail.com</a>.</p>
    </section>
</div>

</div>

</>
  )
}

export default Privacy_Policy