import React from "react";
import AvailableOn from "./AvailableOn";
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";

const Footer = () => {

  const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // This makes the scroll smooth
    });
    }

  const socialLinks = [
    {
      icon: <GoogleIcon fontSize="large"/>,
      link: "https://www.google.com",
    },
    {
      icon: <LinkedInIcon  fontSize="large"/>,
      link: "https://www.linkedin.com",
    },
    {
      icon: <InstagramIcon fontSize="large"/>,
      link: "",
    },
    {
      icon: <FacebookOutlinedIcon fontSize="large"/>,
      link: "#",
    },
    {
      icon: <XIcon fontSize="large"/>,
      link: "#",
    },
    {
      icon: <YouTubeIcon fontSize="large" />,
      link: "https://www.youtube.com",
    },
  ];
  return (
    <>
      {/* <AvailableOn /> */}
      <div className="flex flex-col sm:flex-row justify-between bg-black sm:px-60 w-full h-full sm:h-[300px] pt-10  mt-10">
        <div className="w-[400px] h-fit sm:mb-0 mb-4">
          <img className="h-full w-full object-contain" src="https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/footerlogo.png?alt=media&token=fe9dc616-af1b-46f2-b55f-3ddc2d461236" />
          <div className="flex gap-4 justify-center pt-8">
          {socialLinks.map((link, key) => (
            <a href={link.link} target="_blank" className="text-gray-600">
              {link.icon}
            </a>
          ))}
        </div>
        </div>
        <div className=" flex text-nowrap flex-col text-base font-medium text-white gap-2 items-start pt-4 sm:pr-10 ml-4 sm:ml-4">
          <h1 className=" font-bold text-lg">Menu</h1>
          {/* <Link to="/home">Home</Link> */}
          {/* <Link to="/shop">Shop</Link> */}
          <div className="flex sm:flex-row flex-col gap-0 sm:gap-8">
            <section className="flex flex-col">
              <Link onClick={() => handleScrollToTop()} className="hover:text-green-primary focus:text-green-primary" to="/about">About us</Link>
              <Link onClick={() => handleScrollToTop()} className="hover:text-green-primary focus:text-green-primary" to='/shop'>Shop</Link>
              <Link onClick={() => handleScrollToTop()} className="hover:text-green-primary focus:text-green-primary" to="/blog">Blog</Link>
              <Link onClick={() => handleScrollToTop()} className="hover:text-green-primary focus:text-green-primary" to="contact">Contact </Link>
            </section>
            <section className="flex flex-col">
              <Link onClick={() => handleScrollToTop()} className="hover:text-green-primary focus:text-green-primary" to='/privacyPolicy' >Privacy Policy</Link>
              <Link onClick={() => handleScrollToTop()} className="hover:text-green-primary focus:text-green-primary" to='/returnPolicy'>Return Refund Policy</Link>
              <Link onClick={() => handleScrollToTop()} className="hover:text-green-primary focus:text-green-primary" to='/terms&conditions'>Terms & Conditions</Link>
              <Link onClick={() => handleScrollToTop()} className="hover:text-green-primary focus:text-green-primary" to='/shippingPolicy'>Shipping Policy</Link>
            </section>
          </div>
        </div>
        <div className="flex flex-col gap-4 sm:gap-0 mb-4 sm:mb-0 text-lg text-white pt-4 pl-4 sm:pl-0">
          <h1 className=" font-bold">CONTACT INFO</h1>
          <p>
            Address : Tilak Road, Opp.Kanchanjunga Stadium, Gate No.5,
            Siliguri 734001
          </p>
          <p>Phone No : +91 9932042529</p>
          <p>Email : kolminhealthcare@gmail.com</p>
        </div>
      </div>
      <div className="text-sm font-medium bg-[#FFC700] w-full h-[60px] px-4 py-2 pt-4">
        <p>© Copyright 2024. Designed & Developed by Brior Bridge Dynamics.</p>
      </div>
    </>
  );
};

export default Footer;
