import React from 'react'

const ThankYou = () => {
  return (
    <div>
        <h1 className='text-center text-black'>Thanks for ordering!</h1>
    </div>
  )
}

export default ThankYou