import React from 'react'

const Terms_conditions = () => {
  return (
<>
<div className='flex flex-col items-center'>
    <h1 className='text-2xl font-bold'>Terms and Conditions</h1>
<div className='w-full flex flex-col gap-4 mt-4 px-8'>
    <section>
        <h2 className='text-md font-bold'>1. Introduction</h2>
        <p className='ml-6'>Welcome to Kolmin Healthcare! These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our website, you agree to comply with and be bound by these Terms.</p>
    </section>
    <section>
        <h2 className='text-md font-bold'>2. Use of the Website</h2>
        <ul className='ml-6'>
            <li>You must be at least 18 years old to use our website.</li>
            <li>You agree to use our website for lawful purposes only.</li>
            <li>You must not misuse our website by introducing viruses or other harmful material.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>3. Intellectual Property</h2>
        <ul className='ml-6'>
            <li>All content on our website, including text, graphics, logos, and images, is the property of Kolmin Healthcare or its licensors and is protected by intellectual property laws.</li>
            <li>You may not reproduce, distribute, or otherwise use the content without our express written consent.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>4. User Accounts</h2>
        <ul className='ml-6'>
            <li>To access certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your account information.</li>
            <li>You agree to provide accurate and complete information when creating your account.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>5. Product Information</h2>
        <ul className='ml-6'>
            <li>We strive to provide accurate information about our products. However, we do not warrant that the descriptions or other content are accurate, complete, or error-free.</li>
            <li>Prices and availability of products are subject to change without notice.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>6. Limitation of Liability</h2>
        <ul className='ml-6'>
            <li>Kolmin Healthcare will not be liable for any indirect, incidental, or consequential damages arising out of or in connection with your use of the website or products.</li>
            <li>Our total liability to you for any damages shall not exceed the amount paid by you for the product that gave rise to the claim.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>7. Governing Law</h2>
        <p className='ml-6'>These Terms are governed by and construed in accordance with the laws of Siliguri's Jurisdiction. Any disputes arising out of or relating to these Terms will be resolved in the courts of Siliguri's Jurisdiction.</p>
    </section>
    <section>
        <h2 className='text-md font-bold'>8. Changes to Terms</h2>
        <p className='ml-6'>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the website constitutes your acceptance of the revised Terms.</p>
    </section>
    <section>
        <h2 className='text-md font-bold'>9. Contact Information</h2>
        <p className='ml-6'>For any questions or concerns, please contact us at <a className='text-green-primary' href="mailto:kolminhealthcare@gmail.com">kolminhealthcare@gmail.com</a>.</p>
    </section>
    </div>
</div>
</>
  )
}

export default Terms_conditions