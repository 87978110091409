import React, { useContext, useEffect, useState } from "react";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import RemoveCircleOutlineSharpIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EastIcon from "@mui/icons-material/East";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { AppContext } from "../Services/Context/Appcontext";
import {
  arrayRemove,
  arrayUnion,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { database } from "../Services/firebaseconfig";
import { v4 as uuid } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import SendEmail from "../Components/SendEmail";
import NewOrderMail from "../Components/NewOrderMail";

const Cart = () => {
  const socialLinks = [
    {
      icon: <GoogleIcon />,
      link: "https://www.google.com",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com",
    },
    {
      icon: <InstagramIcon />,
      link: "",
    },
    {
      icon: <FacebookOutlinedIcon />,
      link: "#",
    },
    {
      icon: <XIcon />,
      link: "#",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com",
    },
  ];
  const d = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [quantity, setQuantity] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState();
  const [termsChecked, setTermsChecked] = useState(false);
  const [address, setAddress] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    companyName: "",
    country: "India",
    streetAddress: "",
    city: "",
    state: "",
    pin: "",
    phone: "",
    email: "",
  });
  const { products, logUser, userCart } = useContext(AppContext);
  const navigate = useNavigate();
  const increment = (product) => {
    const newproduct = { ...product, qty: product.qty + 1 };
    updateDoc(doc(database, "cart", logUser.uid), {
      products: arrayUnion(newproduct),
    }).then(
      updateDoc(doc(database, "cart", logUser.uid), {
        products: arrayRemove(product),
      })
    );
  };
  const decrement = (product) => {
    if (product.qty > 2) {
      const newproduct = { ...product, qty: product.qty - 1 };
      updateDoc(doc(database, "cart", logUser.uid), {
        products: arrayUnion(newproduct),
      }).then(
        updateDoc(doc(database, "cart", logUser.uid), {
          products: arrayRemove(product),
        })
      );
    }
  };
  const deleteCart = (product) => {
    updateDoc(doc(database, "cart", logUser.uid), {
      products: arrayRemove(product),
    });
  };

  const subtotal = userCart.products?.reduce((accumulator, product) => {
    return accumulator + parseFloat(Number(product.price) * product.qty);
  }, 0);

  const roundedSubtotal = subtotal ? subtotal.toFixed(2) : "0.00";

  // console.log(roundedSubtotal);


  // Razorpay
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleClick = async () => {
    const data = {
      amount: parseFloat(roundedSubtotal) + 100,
      currency: "INR",
    };

    // const encodedString = btoa("rzp_test_K9MZzTnhUkNDtp:1k2Qgc4rDBDkzNG2oBIkfP0y")
    // console.log(encodedString);

    // const response = await fetch('https://api.razorpay.com/v1/orders', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Basic cnpwX3Rlc3RfSzlNWnpUbmhVa05EdHA6MWsyUWdjNHJEQkRrek5HMm9CSWtmUDB5', // Replace with your Razorpay API key
    //   },
    //   body: JSON.stringify({
    //     amount: 50000, // Adjust the amount as needed
    //     currency: 'INR',
    //   }),
    // });

    const orderId = uuid().slice(0, 8);

    const options = {
      key: "rzp_live_wryDMz36g1Zu2a", // Replace with your actual key ID //key = rzp_live_wryDMz36g1Zu2a | rzp_test_rR7rekzRx9SXR8
      order_id: data.id,
      amount: data.amount * 100,
      currency: data.currency,
      name: "Kolmin Healthcare", // Your business name
      description: `Payment received for order ${orderId}`,
      image:
        "https://firebasestorage.googleapis.com/v0/b/kolmin-healthcare.appspot.com/o/footerlogo.png?alt=media&token=fe9dc616-af1b-46f2-b55f-3ddc2d461236",
      prefill: {
        name: `${address.firstName + " " + address.lastName}`, // Customer's name
        email: `${address.email}`,
        contact: `${address.phone}`, // Customer's phone number
      },
      notes: {
        address:
          "Tilak Road, Opp.Kanchanjunga Stadium, Gate No.5, Siliguri 734001",
      },
      theme: {
        color: "#3399cc",
      },
      handler: function (response) {
        alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        const currOrder = {
          ...userCart,
          paymentId: response.razorpay_payment_id,
          orderId: orderId,
          date:
            `${d.getDate()}` +
            " " +
            monthNames[d.getMonth()] +
            " " +
            `${d.getFullYear()}`,
          status: "Processing",
          paymentMethod: paymentMethod,
          total: subtotal,
          shipping: 100,
          subtotal: (Number(roundedSubtotal) + 100).toFixed(2),
          billingAddress: address,
        };
        const customerName = String(
          `${address.firstName + " " + address.lastName}`
        );
        const orderNumber = String(orderId);
        const cartProducts = userCart.products;
        const shippingCost = 100;
        const total = (Number(roundedSubtotal) + 100).toFixed(2);
        const toEmail = address.email;
        const fullAddress =
          String(address.streetAddress) +
          ", " +
          String(address.city) +
          ", " +
          String(address.state) +
          ", " +
          String(address.pin);
        const contact = String(address.email) + "/" + String(address.phone);
        // console.log(fullAddress);
        // console.log(contact);
        const orderData = {
          customerName,
          fullAddress,
          contact,
          orderNumber,
          cartProducts,
          subtotal,
          shippingCost,
          total,
          toEmail,
        };
        const orderDataSelf = {
          customerName,
          fullAddress,
          contact,
          orderNumber,
          cartProducts,
          subtotal,
          shippingCost,
          total,
          toEmail: "kolminhealthcare@gmail.com",
        };
        // console.log(orderData);
        const templateParams = {
          to_name: "Kolmin Healthcare",
          from_name: address.firstName, // Replace with your company name
          to_email: "swarupd049@gmail.com",
          message: orderId,
        };
        setDoc(
          doc(database, "orders", logUser.uid),
          {
            orders: arrayUnion(currOrder),
          },
          { merge: true }
        )
          .then(
            updateDoc(doc(database, "cart", logUser.uid), {
              products: [],
            })
          )
          .then(
            updateDoc(doc(database, "users", logUser.uid), {
              firstName: address.firstName,
              middleName: address.middleName,
              lastName: address.lastName,
              companyName: address.companyName,
              country: "India",
              streetAddress: address.streetAddress,
              city: address.city,
              state: address.state,
              pin: address.pin,
              phone: address.phone,
              email: logUser.email,
            })
          )
          .then(
            SendEmail(orderData),
            NewOrderMail(orderDataSelf),
            // emailjs.send(
            //   'service_3mjyda2', // Replace with your EmailJS service ID
            //   'template_i7tz618', // Replace with your EmailJS template ID
            //   templateParams,
            //   "idNKvr7k8j7SonckT" // Replace with your EmailJS user ID
            // )
            navigate("/thankyou")
          );
      },
    };

    const rzp = new window.Razorpay(options);
    if (
      address.firstName === "" &&
      address.lastName === "" &&
      address.streetAddress === "" &&
      address.city === "" &&
      address.state === "" &&
      address.pin === "" &&
      address.phone === ""
    ) {
      alert("fill all the details");
    } else {
      if (!paymentMethod) {
        alert("Choose a payment method");
      } else {
        if (termsChecked === false) {
          alert("Please check terms and conditions");
        } else {
          rzp.open();
        }
      }
    }
  };

  const cart = userCart.products?.length;

  return (
    <>
      <section className="px-[4%]">
        <h1 className="text-2xl font-semibold">Your Cart</h1>
        <hr className="border-black mt-4" />

        <div className="flex sm:flex-row flex-col gap-8 mt-10">
          <div className="w-full sm:w-[75%]">
            {/* Left Side */}
            <section className="border-[1px] border-[#DCDBDB] rounded-[10px] overflow-hidden">
              <div className="bg-[#EDEDED] flex justify-between sm:pl-[20px] pl-2 sm:pr-[19%] pr-[16%] py-4 text-xs sm:text-xl ">
                <p>Product</p>
                <p>Name</p>
                <p>Price</p>
                <p>Quantity</p>
                <p>Total</p>
              </div>

              {cart !== 0 ? (
                <>
                  {userCart.products?.map((cartProduct, key) => (
                    <div className="flex items-center justify-between sm:pl-[20px] pl-2 sm:pr-[40px] py-8 border-b-[1px] border-[#DCDBDB] text-xs">
                      <img
                        className="sm:w-[100px] sm:h-[100px] w-[60px] h-[60px] object-cover"
                        src={cartProduct.image[0]}
                      />
                      <p className="w-[12%] text-center">{cartProduct.name}</p>
                      {cartProduct.offer_price !== "" ? (
                        <>
                          <p className="font-semibold">
                            ₹{cartProduct.offer_price}
                          </p>
                        </>
                      ) : (
                        <p className="font-semibold">₹{cartProduct.price}</p>
                      )}
                      <div className="flex justify-around items-center border-[1px] border-[#DCDBDB] w-[60px] sm:w-[120px] py-1 rounded-lg">
                        <button
                          className="sm:contents hidden"
                          onClick={() => decrement(cartProduct)}
                        >
                          <RemoveCircleOutlineSharpIcon />
                        </button>
                        <button
                          className="sm:hidden content"
                          onClick={() => decrement(cartProduct)}
                        >
                          <RemoveCircleOutlineSharpIcon fontSize="sm" />
                        </button>

                        <p className="font-semibold w-4 text-center">
                          {cartProduct.qty}
                        </p>

                        <button
                          className="sm:contents hidden"
                          onClick={() => increment(cartProduct)}
                        >
                          <AddCircleOutlineSharpIcon />
                        </button>
                        <button
                          className="sm:hidden content"
                          onClick={() => increment(cartProduct)}
                        >
                          <AddCircleOutlineSharpIcon fontSize="sm" />
                        </button>
                      </div>
                      <p className="font-semibold">
                        ₹
                        {(Number(cartProduct.price) * cartProduct.qty).toFixed(
                          2
                        )}
                      </p>

                      <button
                        className="w-[40px] text-center"
                        onClick={() => deleteCart(cartProduct)}
                      >
                        <DeleteOutlinedIcon />
                      </button>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <p className="pl-[20px] pr-[40px] py-8 text-xl font-semibold">
                    Start Shopping Now.
                  </p>
                </>
              )}
            </section>

            {logUser?.email !== "" ? (
              <section className="mt-8">
                <p className="text-xl font-semibold mb-2">
                  Login with your Social ID
                </p>
                <div className="flex gap-4">
                  {socialLinks.map((link, key) => (
                    <a
                      href={link.link}
                      target="_blank"
                      className="text-gray-600"
                    >
                      {link.icon}
                    </a>
                  ))}
                </div>
              </section>
            ) : null}

            <section className="border-[1px] border-[#DCDBDB] rounded-[10px] overflow-hidden mt-8">
              <div className="bg-[#EDEDED] pl-[20px] py-4 text-xl ">
                <p className="text-xl font-semibold">BILLING DETAILS</p>
              </div>

              <form
                action="submit"
                className="flex flex-col gap-6 mt-4 mb-8 px-4"
              >
                <div className="flex sm:flex-row flex-col gap-6 mt-4 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="first name">First Name*</label>
                    <input
                      type="text"
                      name="first name"
                      id="first-name"
                      placeholder={logUser?.firstName}
                      value={address.firstName}
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                      onChange={(e) =>
                        setAddress({ ...address, firstName: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="middle name">Middle Name</label>
                    <input
                      type="text"
                      name="middle name"
                      id="middle-name"
                      placeholder={logUser?.middleName}
                      value={address.middleName}
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                      onChange={(e) =>
                        setAddress({ ...address, middleName: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-full sm:w-[49%]">
                  <label htmlFor="first name">Last Name*</label>
                  <input
                    type="text"
                    name="last name"
                    id="last-name"
                    placeholder={logUser?.lastName}
                    value={address.lastName}
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    onChange={(e) =>
                      setAddress({ ...address, lastName: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="company name">Company name(optional)</label>
                  <input
                    type="text"
                    name="company name"
                    id="company-name"
                    placeholder={logUser?.companyName}
                    value={address.companyName}
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    onChange={(e) =>
                      setAddress({ ...address, companyName: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <p>Country/Region*</p>
                  <p>India</p>
                </div>
                {/* Add country */}

                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="street address">Street address*</label>
                  <input
                    type="text"
                    name="street address"
                    id="street-address"
                    placeholder={logUser?.streetAddress}
                    value={address.streetAddress}
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    onChange={(e) =>
                      setAddress({ ...address, streetAddress: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="town">Town / City*</label>
                  <input
                    type="text"
                    name="town"
                    id="town"
                    placeholder={logUser?.city}
                    value={address.city}
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    onChange={(e) =>
                      setAddress({ ...address, city: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="state">State*</label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    placeholder={logUser?.state}
                    value={address.state}
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    onChange={(e) =>
                      setAddress({ ...address, state: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="PIN">PIN Code*</label>
                  <input
                    type="text"
                    name="PIN"
                    id="PIN"
                    placeholder={logUser?.pin}
                    value={address.pin}
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    onChange={(e) =>
                      setAddress({ ...address, pin: e.target.value })
                    }
                  />
                </div>

                <div className="flex sm:flex-row flex-col gap-6 mt-4 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="phone">Phone*</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder={logUser?.phone}
                      value={address.phone}
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                      onChange={(e) =>
                        setAddress({ ...address, phone: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                      placeholder={logUser?.email}
                      value={address.email}
                      onChange={(e) =>
                        setAddress({ ...address, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="flex gap-2 w-full">
                  <input
                    type="checkbox"
                    name="create acc"
                    id="create-acc"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                  <label htmlFor="create acc">Create an account?</label>
                </div>
                <div class="col-span-full">
                  <label
                    for="additional-info"
                    class="block text-lg font-medium leading-6 text-gray-900"
                  >
                    ADDITIONAL INFORMATION
                  </label>
                  <div class="mt-4">
                    <textarea
                      id="additional-info"
                      name="additional-info"
                      rows="3"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-md sm:leading-6"
                    ></textarea>
                  </div>
                </div>
              </form>
            </section>
          </div>

          <div className="w-full sm:w-[25%]">
            {/* Right Side */}
            <section className="border-[1px] border-[#DCDBDB] rounded-[10px] overflow-hidden">
              <div className="bg-[#EDEDED] pl-[20px] py-4 text-xl ">
                <p className="text-xl font-semibold">Apply Coupon</p>
              </div>
              <div className="w-full flex justify-center">
                <input
                  type="text"
                  name="first name"
                  id="first-name"
                  className=" w-[90%] my-4 rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                />
              </div>
            </section>

            <section className="border-[1px] mt-4 border-[#DCDBDB] rounded-[10px] overflow-hidden">
              <div className="bg-[#EDEDED] pl-[20px] py-4 text-xl ">
                <p className="text-xl font-semibold">Cart Total</p>
              </div>
              <div className="flex items-center px-6 py-4 text-lg font-medium">
                <p>Subtotal</p>
                <p className="ml-auto text-sm">₹{roundedSubtotal}</p>
              </div>
              <div className="flex items-center px-6 py-4 text-lg font-medium">
                <p>Shipping Charge</p>
                <p className="ml-auto text-sm">₹ 100.00</p>
              </div>
              <div className="flex items-center px-6 py-4 text-lg font-medium">
                <p>Total</p>
                <p className="ml-auto text-sm">
                  ₹{(Number(roundedSubtotal) + 100).toFixed(2)}
                </p>
              </div>
            </section>

            <section className="border-[1px] mt-4 border-[#DCDBDB] rounded-[10px] overflow-hidden">
              <div className="bg-[#EDEDED] pl-[20px] py-4 text-xl ">
                <p className="text-xl font-semibold">Payment Method</p>
              </div>
              <div>
                {/* <div className="flex items-center ps-4 border-b-[1px] border-[#DCDBDB] ">
                  <input
                    id="COD"
                    type="radio"
                    value="COD"
                    name="payment"
                    className="w-4 h-4 text-blue-600 focus:outline-none bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />

                  <label
                    htmlFor="COD"
                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Cash On Delivery
                  </label>
                </div> */}
                <div className="flex items-center ps-4 border-b-[1px] border-[#DCDBDB] ">
                  <input
                    id="card"
                    type="radio"
                    value="Credit Card/Debit Card/NetBanking"
                    name="payment"
                    className="w-4 h-4 text-blue-600 focus:outline-none bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />

                  <label
                    htmlFor="card"
                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Credit Card/Debit Card/NetBanking
                  </label>
                </div>
                {/* <div className="flex items-center ps-4 ">
                  <input
                    id="UPI"
                    type="radio"
                    value="UPI"
                    name="payment"
                    className="w-4 h-4 text-blue-600 focus:outline-none bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />

                  <label
                    htmlFor="UPI"
                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    UPI
                  </label>
                </div> */}
              </div>
            </section>

            <section className="mt-8">
              <div className="flex items-start gap-2">
                <input
                  type="checkbox"
                  name="terms-agree"
                  className="mt-[6px]"
                  checked={termsChecked}
                  onClick={(e) => setTermsChecked(e.target.checked)}
                />
                <p>
                  I have read and agree to the website{" "}
                  <Link
                    to="/terms&conditions/"
                    className="underline underline-offset-2"
                  >
                    terms and conditions
                  </Link>
                  *
                </p>
              </div>

              <button
                onClick={() => handleClick()}
                className="w-full flex items-center justify-center gap-2 bg-[#4F9F1E] text-white py-2 rounded-xl mt-4 font-semibold "
              >
                PROCEED TO CHECKOUT
                <EastIcon />
              </button>
              <button className="w-full flex items-center justify-center gap-2 py-2 rounded-xl mt-4 text-sm font-semibold ">
                <ArrowBackIosIcon />
                CONTINUE SHOPPING
              </button>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
