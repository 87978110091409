import React, { useContext, useState } from "react";
import { AppContext } from "../../Services/Context/Appcontext";
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { database } from "../../Services/firebaseconfig";

const Inventory = () => {
  const { products } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [thisProduct, setThisProduct] = useState();
  const [productCount, setProductCount] = useState(3);
  console.log(productCount, products.length);

  return (
    <>
      <h1 className="text-black font-bold text-3xl mb-4">Inventory</h1>
      <div className="grid grid-cols-4 items-center">
        {/* {products?.slice(0, productCount).map((product) => ( */}
        {products?.map((product) => (
          <div className="w-[200px] h-[250px]">
            <img
              src={product.image[0]}
              alt=""
              className="w-[70%] h-[150px] contain"
            />
            <h3 className="text-black">{product.name}</h3>
            <Link>
              <button
                onClick={() => {
                  setOpenModal(true);
                  setThisProduct(product);
                }}
              >
                Modify Product
              </button>
            </Link>
          </div>
        ))}
        <ProductExpanded
          openModal={openModal}
          setOpenModal={setOpenModal}
          product={thisProduct}
        />
        {/* {productCount === products.length ? null : (
          <button
            onClick={() => setProductCount(products.length)}
            className="py-2 px-3 bg-green-primary text-white rounded-lg mt-2 text-center w-[100px]"
          >
            See All
          </button>
        )} */}
      </div>
    </>
  );
};

const ProductExpanded = ({ product, openModal, setOpenModal }) => {
  //   console.log(product);

  const [thisProduct, setThisProduct] = useState({
    name: "",
    price: "",
    offer_price: "",
    sku: "",
    MOQ: 0,
    newStock: "",
    category: "",
    desc_title: "",
    details: "",
    benefits: [],
    indications: [],
    onSale: product?.onSale,
    use: [],
  });

  console.log(thisProduct);

  const [benefitInputs, setBenefitInputs] = useState([]);
  const [indicationInputs, setIndicationInputs] = useState([]);
  const [useInputs, setUseInputs] = useState([]);

  const handleAddInput = (type) => {
    if (type === "benefits") {
      setBenefitInputs([...benefitInputs, ""]);
    } else if (type === "indications") {
      setIndicationInputs([...indicationInputs, ""]);
    } else if (type === "use") {
      setUseInputs([...useInputs, ""]);
    }
  };

  const handleInputChange = (type, index, value) => {
    const newValues = [...thisProduct[type]];
    newValues[index] = value;
    setThisProduct({ ...thisProduct, [type]: newValues });
  };

  const handleRemoveInput = (type, index) => {
    const newInputs = [...thisProduct[type]];
    newInputs.splice(index, 1);
    setThisProduct({ ...thisProduct, [type]: newInputs });

    if (type === "benefits") {
      setBenefitInputs(newInputs);
    } else if (type === "indications") {
      setIndicationInputs(newInputs);
    } else if (type === "use") {
      setUseInputs(newInputs);
    }
  };

  const updateProduct = (id) => {
    if (thisProduct.name !== "") {
      updateDoc(doc(database, "products", id), {
        name: thisProduct.name,
      }).then(setOpenModal(false));
    }
    if (thisProduct.price !== "") {
      updateDoc(doc(database, "products", id), {
        price: thisProduct.price,
      }).then(setOpenModal(false));
    }
    if (thisProduct.offer_price !== "") {
      updateDoc(doc(database, "products", id), {
        offer_price: thisProduct.offer_price,
      }).then(setOpenModal(false));
    }
    if (thisProduct.sku !== "") {
      updateDoc(doc(database, "products", id), {
        sku: thisProduct.sku,
      }).then(setOpenModal(false));
    }
    if (thisProduct.MOQ !== 0) {
      updateDoc(doc(database, "products", id), {
        MOQ: thisProduct.MOQ,
      }).then(setOpenModal(false));
    }
    if (thisProduct.newStock !== "") {
      updateDoc(doc(database, "products", id), {
        stock: thisProduct.newStock,
      }).then(setOpenModal(false));
    }
    if (thisProduct.category !== "") {
      updateDoc(doc(database, "products", id), {
        category: thisProduct.category,
      }).then(setOpenModal(false));
    }
    if (thisProduct.desc_title !== "") {
      updateDoc(doc(database, "products", id), {
        desc_title: thisProduct.desc_title,
      }).then(setOpenModal(false));
    }
    if (thisProduct.details !== "") {
      updateDoc(doc(database, "products", id), {
        details: thisProduct.details,
      }).then(setOpenModal(false));
    }
    if (thisProduct.benefits.length !== 0) {
      updateDoc(doc(database, "products", id), {
        benefits: arrayUnion(thisProduct.benefits),
      }).then(setOpenModal(false));
    }
    if (thisProduct.indications.length !== 0) {
      updateDoc(doc(database, "products", id), {
        indications: arrayUnion(thisProduct.indications),
      }).then(setOpenModal(false));
    }
    if (thisProduct.onSale !== false) {
      updateDoc(doc(database, "products", id), {
        onSale: thisProduct.onSale,
      }).then(setOpenModal(false));
    }
    if (thisProduct.use.length !== 0) {
      updateDoc(doc(database, "products", id), {
        use: arrayUnion(thisProduct.use),
      }).then(setOpenModal(false));
    }
  };

  return (
    <>
      <Modal
        show={openModal}
        size="5xl"
        onClose={() => setOpenModal(false)}
        popup
        dismissible
      >
        <Modal.Header>Modify - {product?.name}</Modal.Header>
        <Modal.Body>
          <div className="flex items-center text-center gap-2">
            <img
              src={product?.image[0]}
              alt=""
              className="w-[500px] h-[450px] contain"
            />
            <div className="flex flex-col gap-2 items-center w-full justify-between">
              <div className="w-3/4 flex flex-col gap-2">
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">Product Name*</label>
                  <input
                    placeholder={product?.name}
                    onChange={(e) =>
                      setThisProduct({ ...thisProduct, name: e.target.value })
                    }
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">Price*</label>
                  <input
                    placeholder={product?.price}
                    onChange={(e) =>
                      setThisProduct({ ...thisProduct, price: e.target.value })
                    }
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">Offer Price*</label>
                  <input
                    placeholder={product?.offer_price}
                    onChange={(e) =>
                      setThisProduct({
                        ...thisProduct,
                        offer_price: e.target.value,
                      })
                    }
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">SKU*</label>
                  <input
                    placeholder={product?.sku}
                    onChange={(e) =>
                      setThisProduct({ ...thisProduct, sku: e.target.value })
                    }
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">MOQ*</label>
                  <input
                    placeholder={product?.MOQ}
                    onChange={(e) =>
                      setThisProduct({ ...thisProduct, MOQ: e.target.value })
                    }
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="product-name">Category*</label>
                  <input
                    placeholder={product?.category}
                    onChange={(e) =>
                      setThisProduct({
                        ...thisProduct,
                        category: e.target.value,
                      })
                    }
                    type="text"
                    className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="stock">Enter new stock</label>
                  <input
                    type="number"
                    placeholder={product?.stock}
                    onChange={(e) =>
                      setThisProduct({
                        ...thisProduct,
                        newStock: e.target.value,
                      })
                    }
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="stock">Enter product short description</label>
                  <input
                    type="text"
                    placeholder={product?.desc_title}
                    onChange={(e) =>
                      setThisProduct({
                        ...thisProduct,
                        desc_title: e.target.value,
                      })
                    }
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="stock">
                    Enter product details (No. of tablets or quantity in ML)
                  </label>
                  <input
                    type="text"
                    placeholder={product?.details}
                    onChange={(e) =>
                      setThisProduct({
                        ...thisProduct,
                        details: e.target.value,
                      })
                    }
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="benefits">Enter benefits</label>
                  {benefitInputs.map((_, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="text"
                        value={thisProduct.benefits[index] || ""}
                        onChange={(e) =>
                          handleInputChange("benefits", index, e.target.value)
                        }
                        className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1 my-2"
                      />
                      <button
                        onClick={() => handleRemoveInput("benefits", index)}
                        className="ml-2"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => handleAddInput("benefits")}
                    className="mt-2"
                  >
                    Add benefit
                  </button>
                </div>

                <div className="flex flex-col text-left">
                  <label htmlFor="indications">Enter indications</label>
                  {indicationInputs.map((_, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="text"
                        value={thisProduct.indications[index] || ""}
                        onChange={(e) =>
                          handleInputChange(
                            "indications",
                            index,
                            e.target.value
                          )
                        }
                        className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1 my-2"
                      />
                      <button
                        onClick={() => handleRemoveInput("indications", index)}
                        className="ml-2"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => handleAddInput("indications")}
                    className="mt-2"
                  >
                    Add indication
                  </button>
                </div>

                <div className="flex flex-col text-left">
                  <label htmlFor="use">Enter usage</label>
                  {useInputs.map((_, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="text"
                        value={thisProduct.use[index] || ""}
                        onChange={(e) =>
                          handleInputChange("use", index, e.target.value)
                        }
                        className="rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1 my-2"
                      />
                      <button
                        onClick={() => handleRemoveInput("use", index)}
                        className="ml-2"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => handleAddInput("use")}
                    className="mt-2"
                  >
                    Add use
                  </button>
                </div>

                <div className="flex flex-row items-center gap-2 text-left">
                  <label htmlFor="stock">Product on sale?</label>
                  <input
                    type="checkbox"
                    checked={thisProduct.onSale}
                    onChange={(e) =>
                      setThisProduct({
                        ...thisProduct,
                        onSale: e.target.checked,
                      })
                    }
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
              </div>
              <div className="mt-10">
                <h4 className="mb-1 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to update this product?
                </h4>
                <div className="flex justify-center gap-4">
                  <button
                    color="failure"
                    onClick={() => updateProduct(product.id)}
                  >
                    {"Yes, I'm sure"}
                  </button>
                  <button color="gray" onClick={() => setOpenModal(false)}>
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Inventory;
