import React, { useContext, useState } from 'react'
import ProductsArray from '../Components/ProductsArray'
import { AppContext } from '../Services/Context/Appcontext'
import ShopSidebar from '../Components/ShopSidebar'

const Shop = () => {
    const {products, logUser,userCart} = useContext(AppContext)

    const [selectedCategory, setSelectedCategory] = useState("All")
    const [selectedPrice, setSelectedPrice] = useState("100")
    const [filterProductbyPrice, setFilterProductbyPrice] = useState([])

    function filterProductsByCategory(products, selectedCategory) {
        // Check if selectedCategories array is empty
        if (selectedCategory ===  "All") {
            // If no categories are selected, return all products
            return products;
        }
    
        // Filter products based on selected categories
        const filteredProducts = products.filter(product => {
            // Check if the product category exists in the selectedCategories array
            return selectedCategory.includes(product.category);
        });
    
        return filteredProducts;
    }

    function filterProductsByPrice(products, minPrice, maxPrice) {
        // Filter products based on price range
        const filteredProducts = products.filter(product => {
            // Assuming each product object has a price property
            const productPrice = parseFloat(product.price); // Convert price to a numeric value
            return productPrice >= minPrice && productPrice <= maxPrice;
        });
    
        return filteredProducts;
    }
    
    const filteredProducts = filterProductsByCategory(products, selectedCategory)

    // console.log(filterProductbyPrice);
    
    return (
        <div className='flex sm:flex-row flex-col-reverse'>
            <ShopSidebar products={products} filteredProducts={filteredProducts} setSelectedCategory={setSelectedCategory} setSelectedPrice={setSelectedPrice} selectedPrice={selectedPrice} setFilterProductbyPrice={setFilterProductbyPrice} filterProductsByPrice={filterProductsByPrice}/>
            <div className='sm:contents hidden'>
                {products?<ProductsArray array={filterProductbyPrice.length===0? filteredProducts : filterProductbyPrice} col={3}/>:
                <h1>Loading...</h1>
                }
            </div>
            {/* <div className='lg:contents hidden'>
                {products?<ProductsArray array={filterProductbyPrice.length===0? filteredProducts : filterProductbyPrice} col={3}/>:
                <h1>Loading...</h1>
                }
            </div> */}
            {/* This is for mobile */}
            <div className='contents sm:hidden'>
                {products?<ProductsArray array={filterProductbyPrice.length===0? filteredProducts : filterProductbyPrice} col={2}/>:
                <h1>Loading...</h1>
                }
            </div>
        </div>
    )
}

export default Shop
