// FirebaseContext.js
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
} from "firebase/firestore";
import React, { createContext, useState, useEffect } from "react";
import { auth, database } from "../firebaseconfig";
import { onAuthStateChanged } from "firebase/auth";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [logUser, setLogUser] = useState();
  const [loading, isLoading] = useState(true);
  const [userCart, setUserCart] = useState([])

  useEffect(() => {
    const fetchData = async () => {
        // Fetch products
        const prouductSnapshot = await getDocs(collection(database, "products"));
        const tempProducts = prouductSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setProducts(tempProducts);

        // Fetch blogs
        const blogsSnapshot = await getDocs(collection(database, "blogs"));
        const tempBlogs = blogsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setBlogs(tempBlogs);

        // Fetch all orders
        const allOrdersSnapshot = await getDocs(collection(database, "orders"));
        const tempAllOrders = allOrdersSnapshot.docs.map(doc => ({ ...doc.data().orders}));
        setAllOrders(tempAllOrders);

        // Fetch user data
        const currUser = auth.currentUser;
        console.log(currUser);
        if (currUser) {
            onSnapshot(doc(database, "users", currUser.uid), (doc) => {
                setLogUser({ ...doc.data(), uid: currUser.uid });
                isLoading(false);
            });
        } else {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    onSnapshot(doc(database, "users", user.uid), (doc) => {
                      setLogUser({ ...doc.data(), uid: user.uid });
                        isLoading(false);
                    });
                } else {
                    // Handle the case when the user is not authenticated
                    console.log("User is not authenticated");
                    isLoading(false);
                }
            });
            return () => unsubscribe();
        }

        // Fetch user's cart
        if (currUser) {
          onSnapshot(doc(database, "cart", currUser.uid), (doc) => {
              setUserCart({ ...doc.data(), uid: currUser.uid });
              // isLoading(false);
          });
      } else {
          const unsubscribe = onAuthStateChanged(auth, (user) => {
              if (user) {
                  onSnapshot(doc(database, "cart", user.uid), (doc) => {
                    setUserCart({ ...doc.data(), uid: user.uid });
                      // isLoading(false);
                  });
              } else {
                  // Handle the case when the user is not authenticated
                  console.log("User is not authenticated");
                  // isLoading(false);
              }
          });
          return () => unsubscribe();
      }

      // Fetch user's orders
      if (currUser) {
        onSnapshot(doc(database, "orders", currUser.uid), (doc) => {
            setOrders({ ...doc.data(), uid: currUser.uid });
            // isLoading(false);
        });
    } else {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                onSnapshot(doc(database, "orders", user.uid), (doc) => {
                  setOrders({ ...doc.data(), uid: user.uid });
                  orders.reverse()
                    // isLoading(false);
                });
            } else {
                // Handle the case when the user is not authenticated
                console.log("User is not authenticated");
                // isLoading(false);
            }
        });
        return () => unsubscribe();
    }

    };
    fetchData();
}, []);

  if (loading) {
    <AppContext.Provider value={{ loading: loading }}>
      {children}
    </AppContext.Provider>;
  }
  return (
    <AppContext.Provider value={{ products: products,blogs:blogs, logUser: logUser, userCart:userCart, orders:orders, allOrders:allOrders }}>
      {children}
    </AppContext.Provider>
  );
};
