import React, { useState } from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, providerGoogle } from "../Services/firebaseconfig";

const LoginForm = () => {
  const navigate = useNavigate();
  
  const socialLinks = [
    {
      icon: <GoogleIcon />,
      link: "#",
      function:googleLogin
    },
    {
      icon: <LinkedInIcon />,
      link: "#",
      function:''
    },
    {
      icon: <InstagramIcon />,
      link: "#",
      function:''
    },
    {
      icon: <FacebookOutlinedIcon />,
      link: "#",
      function:''
    },
    {
      icon: <XIcon />,
      link: "#",
      function:''
    },
    {
      icon: <YouTubeIcon />,
      link: "#",
      function:''
    },
  ];


  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [err, setErr] = useState("");

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const isValidEmail = emailRegex.test(user.email);

  const [isPasswordVisible, setIsPasswordVisible] = useState({
    pass: false,
    confirm: false,
  });

  function togglePasswordVisibility(field) {
    setIsPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  }

  function login() {
    if (isValidEmail === true && user.email !== "") {
      setErr("");
      if (user.password.length < 7) {
        setErr("Password should be minimum 8 characters");
      } else {
         {
          setErr("");
          const {email} = user
          console.log(email);
          try{
            signInWithEmailAndPassword(auth, user.email, user.password).then(res=> {
              if(res.user){
                navigate('/shop')
                window.location.reload()
              }
              })
          }
          catch(e){

          }
          
        }
      }
    } else {
      setErr("Enter valid email");
    }
  }

  function forgetPassword(){
    if(user.email){
      sendPasswordResetEmail(auth, user.email)
      setErr("Check your inbox for resetting password")
    }else{
      setErr("Email is required")
    }
  }

  function googleLogin(){
    signInWithPopup(auth, providerGoogle)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    if(user){
      navigate("/")
    }
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });
  }


  return (
    <div className="flex flex-col items-center justify-center w-[310px]">
      <h1 className="text-2xl">Login to your account</h1>
      <form action="submit" className="flex flex-col pt-16 gap-6">
        <label htmlFor="email">Enter username or e-mail</label>
        <input type="email" name="email" id="email" className="w-[310px] rounded-xl bg-transparent border-[1px] border-black pl-2 py-1" onChange={(e) => setUser({ ...user, email: e.target.value })}/>
        <label htmlFor="passwor">Enter password</label>
        <input type="password" className="w-[310px] rounded-xl bg-transparent border-[1px] border-black pl-2 py-1" onChange={(e) => setUser({ ...user, password: e.target.value })}/>
      </form>
      {err != "" ? <p>{err}</p> : null}
      <div className="pt-3 flex items-center justify-between w-full text-sm">
        <div className="flex items-center gap-1">
        <input type="checkbox" name="remember" id="remember" />
        <label htmlFor="remember">Remember me</label>
        </div>
        <button onClick={()=>forgetPassword()}>
          Forgot password <EastOutlinedIcon />
        </button>
      </div>
      <div className="text-center">
        <p className="pt-[34px] pb-[26px] text-sm">or login with</p>
        <div className="flex gap-4">
          {socialLinks.map((link, key) => (
            <a onClick={()=>link.function()} target="_blank" className="text-gray-600 cursor-pointer">
              {link.icon}
            </a>
          ))}
        </div>
        <button onClick={()=>login()} className="py-2 px-4 rounded-full mt-8 bg-green-600 text-white">Log in<EastOutlinedIcon/></button>
      </div>
    </div>
  );
};

export default LoginForm;
