import React from 'react'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useLocation } from 'react-router-dom';

const My_order = () => {

    const {state} = useLocation()
    const orderItem = state.orderItem
   console.log(orderItem);

  return (
<>

<section>
    <p className='text-sm'>Order <b>#{orderItem.orderId}</b> was placed on <b>{orderItem.date}</b> and is currently <b>{orderItem.status}</b>.</p>
    <p className='text-2xl font-bold mt-6'>Order Details</p>
    <div className='border-[1px] border-[#DCDBDB] rounded-lg mt-6' >
        <div className='w-full flex justify-around py-2 border-b-[1px] border-[#DCDBDB]'>
            <p className='min-w-[100px] text-lg text-center font-semibold'>Product</p>
            <p className='min-w-[100px] text-lg text-center font-semibold'>Total</p>
        </div>
        {orderItem.products?.map((product)=>(
        <div className='w-full flex justify-around py-2 ' key={product.sku}>
            <p className='min-w-[100px] max-w-[200px] text-lg text-wrap text-center '>{product.name} <b>x {product.qty}</b></p>
            <p className='min-w-[100px] max-w-[200px] text-lg text-wrap text-center '>Rs {orderItem.subtotal}</p>
        </div>
        ))}
        <div className='border-[1px] border-[#DCDBDB]'>
            <div className='flex border-b-[1px] border-[#DCDBDB] items-center justify-between px-4 font-semibold text-lg'>
                <p className='w-[50%] py-2 border-r-[1px] border-[#DCDBDB]'>Subtotal:</p>
                <p>Rs {orderItem.subtotal}</p>
            </div>
            <div className='flex border-b-[1px] border-[#DCDBDB] items-center justify-between px-4 font-semibold text-lg'>
                <p className='w-[50%] py-2 border-r-[1px] border-[#DCDBDB]'>Payment Method:</p>
                <p>{orderItem.paymentMethod}</p>
            </div>
            <div className='flex items-center justify-between px-4 font-semibold text-lg'>
                <p className='w-[50%] py-2 border-r-[1px] border-[#DCDBDB]'>Total</p>
                <p>Rs 120.00</p>
            </div>

        </div>
    </div>
        <div className='w-full mt-8'>
                <h1 className='text-2xl font-bold'>Billing Address</h1>
            <div className='w-full flex flex-col gap-2 px-2 py-4 border-[1px] border-[#DCDBDB] rounded-lg mt-4 mb-8'>
                <p>{orderItem.billingAddress.firstName} {orderItem.billingAddress.middleName} {orderItem.billingAddress.lastName}</p>
                {orderItem.billingAddress.companyName!==""?<p>{orderItem.billingAddress.companyName}</p>:null}
                <p>{orderItem.billingAddress.streetAddress}</p>
                <p>{orderItem.billingAddress.city}, {orderItem.billingAddress.state}</p>
                <p>{orderItem.billingAddress.pin}</p>
                <p>{orderItem.billingAddress.country}</p>
                <p><PhoneEnabledIcon/> {orderItem.billingAddress.phone}</p>
                <p><MailOutlineIcon/> {orderItem.billingAddress.email}</p>
            </div>
        </div>
</section>

</>
)
}

export default My_order