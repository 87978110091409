import React, { useContext, useState } from "react";
import { AppContext } from "../../Services/Context/Appcontext";
import { Link } from "react-router-dom";

const AllOrders = () => {
  const [ordersCount, setOrdersCount] = useState(2);
  const { allOrders } = useContext(AppContext);

  let length = [];
  length = allOrders?.map((userOrder) => {
    return Object.entries(userOrder).length;
  });

  console.log(length);

  const allOrderLength = length.reduce((num, sum = 0) => sum + num, 0);

  return (
    <>
      <h1 className="text-black font-bold text-3xl mb-4">Manage Orders</h1>
      <section className="w-full border-[1px] border-[#DCDBDB] rounded-lg">
        <div className="flex justify-between py-2 px-10 text-md font-semibold border-b-[1px] border-[#DCDBDB]">
          <p className="min-w-[100px] text-center ">Order</p>
          <p className="min-w-[100px] text-center">Ordered by</p>
          <p className="min-w-[100px] text-center">Date</p>
          <p className="min-w-[100px] text-center ">Status</p>
          <p className="min-w-[100px] text-center">Total</p>
          {/* <p className="min-w-[100px] text-center">Actions</p> */}
        </div>
        {allOrders?.map((userOrder) => (
          <>
            {Object.entries(userOrder)
              .slice(0, ordersCount)
              .map((order) => (
                <div className="flex justify-between py-6 px-10 items-center text-md border-b-[1px] border-[#DCDBDB]">
                  <p className="min-w-[100px] max-w-[100px] text-center text-wrap">
                    #{order[1].orderId}
                  </p>
                  <p className="min-w-[100px] max-w-[100px] text-center text-wrap">
                    {order[1].billingAddress.firstName+ " " +order[1].billingAddress.lastName}
                  </p>
                  <p className="min-w-[100px] max-w-[100px] text-center text-wrap">
                    {order[1].date}
                  </p>
                  <p className="min-w-[100px] max-w-[100px] text-center text-wrap">
                    {order[1].status}
                  </p>
                  <p className="min-w-[100px] max-w-[100px] text-center text-wrap">
                    Rs {order[1].subtotal}
                  </p>
                  {/* <Link
                    to={`/my_account/order/${order[1].orderId}`}
                    state={{ orderItem: order[1] }}
                    className="px-8 py-2 bg-green-primary rounded-lg text-white font-semibold"
                  >
                    View
                  </Link> */}
                </div>
              ))}
          </>
        ))}
      </section>
      <>
      {
        ordersCount === allOrderLength ? null :<button onClick={() => setOrdersCount(allOrderLength)} className="py-2 px-3 bg-green-primary text-white rounded-lg mt-2 text-center">See All
      </button>
      }
      </>
      
    </>
  );
};

export default AllOrders;
