import React from 'react'

const Return_policy = () => {
  return (
<>

<div className='flex flex-col items-center'>

<h1 className='text-2xl font-bold'>Return and Refund Policy</h1>
<div className='w-full flex flex-col gap-4 mt-4 px-8'>
    <section>
        <h2 className='text-md font-bold'>1. Returns</h2>
        <ul className='ml-6'>
            <li>You have <strong>[30]</strong> days from the date of purchase to return an item.</li>
            <li>Items must be unused, in the same condition as received, and in the original packaging.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>2. Refunds</h2>
        <ul className='ml-6'>
            <li>Once we receive your return, we will inspect the item and notify you of the approval or rejection of your refund.</li>
            <li>If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within <strong>[10]</strong> business days.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>3. Exchanges</h2>
        <ul className='ml-6'>
            <li>We only replace items if they are defective or damaged. If you need to exchange an item, please contact us at <a className='text-green-primary' href="mailto:Kolminhealthcare@gmail.com">Kolminhealthcare@gmail.com</a>.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>4. Shipping</h2>
        <ul className='ml-6'>
            <li>You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable.</li>
            <li>If you receive a refund, the cost of return shipping will be deducted from your refund.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>5. Non-Returnable Items</h2>
        <ul className='ml-6'>
            <li>Certain items, such as perishable goods, custom products, and personal care items, are not eligible for return.</li>
        </ul>
    </section>
    <section>
        <h2 className='text-md font-bold'>6. Contact Information</h2>
        <p className='ml-6'>For any questions about returns or refunds, please contact us at <a className='text-green-primary' href="mailto:Kolminhealthcare@gmail.com">Kolminhealthcare@gmail.com</a>.</p>
    </section>
</div>
</div>

</>
  )
}

export default Return_policy