import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css"
import { Provider } from "react-redux";
import store from "./Services/Redux/store";
import { AppProvider } from "./Services/Context/Appcontext";
import Auth from "./Pages/Auth";
import Layout from "./Layout";
import Aboutus from "./Pages/Aboutus";
import Shop from "./Pages/Shop";
import Blogpage from "./Pages/Blogpage";
import Contactus from "./Pages/Contactus";
import Wishlist from "./Pages/Wishlist";
import Homepage from "./Pages/Homepage"
import BlogPage_2 from "./Pages/BlogPage_2";
import Cart from "./Pages/Cart";
import Account_sidebar from "./Pages/Account_sidebar";
import Dashboard from "./Components/My account/Dashboard";
import Orders from "./Components/My account/Orders";
import Addresses from "./Components/My account/Addresses";
import Billing_address from "./Components/My account/Billing_address";
import Account_details from "./Components/My account/Account_details";
import My_order from "./Components/My account/My_order";
import Log_out from "./Components/My account/Log_out";
import ThankYou from "./Pages/ThankYou";
import Product from "./Pages/Product";
import Footer from "./Components/Footer";
import Terms_conditions from "./Pages/Terms_conditions";
import Privacy_Policy from "./Pages/Privacy_Policy";
import Return_policy from "./Pages/Return_policy";
import Shipping_policy from "./Pages/Shipping_policy";
import Inventory from "./Components/Admin panel/Inventory";
import AdminPanel from "./Pages/AdminPanel";
import StockManagement from "./Components/Admin panel/StockManagement";
import AllOrders from "./Components/Admin panel/AllOrders";
import AddProduct from "./Components/Admin panel/AddProduct";

function App() {
  return (
    <div className="overflow-hidden">
      <AppProvider>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/blog">
                  <Route index element={<Blogpage />}/>
                  <Route path="/blog/:id" element={<BlogPage_2 />} />
                </Route>
                <Route path="/shop" element={<Shop />} />
                <Route path="/about" element={<Aboutus />} />
                <Route path="/terms&conditions" element={<Terms_conditions />} />
                <Route path="/privacyPolicy" element={<Privacy_Policy />} />
                <Route path="/returnPolicy" element={<Return_policy />} />
                <Route path="/shippingPolicy" element={<Shipping_policy />} />
                {/* <Route path="/footer" element={<Footer/>}/> */}
                {/* <Route path="/home" element={<Homepage/>}/> */}
                <Route index element={<Homepage />} />
                <Route path="/contact" element={<Contactus />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/home" element={<Homepage />} />
                {/* <Route path="/home" element={<Homepage/>}/> */}
                <Route index element={<Homepage />} />
                <Route path="/contact" element={<Contactus />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/thankyou" element={<ThankYou />} />
                <Route path="/my_account" element={<Account_sidebar />}>
                  <Route index element={<Dashboard />} />
                  <Route path="/my_account/orders" element={<Orders />} />
                  <Route path="/my_account/order">
                    <Route path="/my_account/order/:id" element={<My_order />} />
                  </Route>
                  <Route path="/my_account/addresses" element={<Addresses />} />
                  <Route path="/my_account/billing" element={<Billing_address />} />
                  <Route path="/my_account/details" element={<Account_details />} />
                  <Route path="/my_account/log_out" element={<Log_out />} />
                  <Route path="/my_account/admin_panel">
                    <Route index element={<AdminPanel />}/>
                    <Route path="/my_account/admin_panel/products/modify" element={<Inventory/>}/>
                    <Route path="/my_account/admin_panel/products/stock" element={<StockManagement/>}/>
                    <Route path="/my_account/admin_panel/products/add" element={<AddProduct/>}/>
                    <Route path="/my_account/admin_panel/orders" element={<AllOrders/>}/>
                  </Route>
                </Route>
                <Route path='/product'>
                  <Route path='/product/:id' element={<Product />} />
                </Route>

              </Route>
              <Route path="/auth" element={<Auth />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </AppProvider>
    </div>
  );
}

export default App;
