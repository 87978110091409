import React from 'react'

const Billing_address = () => {
  return (
<>
<section className="border-[1px] border-[#DCDBDB] rounded-[10px] overflow-hidden mb-8">
              <div className="bg-[#EDEDED] pl-[20px] py-4 text-xl ">
                <p className="text-xl font-semibold">BILLING DETAILS</p>
              </div>

              <form
                action="submit"
                className="flex flex-col gap-6 mt-4 mb-8 px-4"
              >
                <div className="flex sm:flex-row flex-col gap-6 mt-4 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="first name">First Name*</label>
                    <input
                      type="text"
                      name="first name"
                      id="first-name"
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="middle name">Middle Name</label>
                    <input
                      type="text"
                      name="middle name"
                      id="middle-name"
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2 sm:w-[49%] w-full">
                  <label htmlFor="first name">Last Name*</label>
                  <input
                    type="text"
                    name="last name"
                    id="last-name"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="company name">Company name(optional)</label>
                  <input
                    type="text"
                    name="company name"
                    id="company-name"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <p>Country/Region*</p>
                  <p className='font-bold'>India</p>
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="street address">Street address*</label>
                  <input
                    type="text"
                    name="street address"
                    id="street-address"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="town">Town / City*</label>
                  <input
                    type="text"
                    name="town"
                    id="town"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="state">State*</label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="PIN">PIN Code*</label>
                  <input
                    type="text"
                    name="PIN"
                    id="PIN"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                </div>

                <div className="flex sm:flex-row flex-col gap-6 mt-4 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="phone">Phone*</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                    />
                  </div>
                </div>
                <div className="flex gap-2 w-full">
                  <input
                    type="checkbox"
                    name="create acc"
                    id="create-acc"
                    className=" rounded-xl bg-transparent border-[1px] border-[#E5E4E4] pl-2 py-1"
                  />
                  <label htmlFor="create acc">Create an account?</label>
                </div>
                <div class="col-span-full">
                  <label
                    for="additional-info"
                    class="block text-lg font-medium leading-6 text-gray-900"
                  >
                    ADDITIONAL INFORMATION
                  </label>
                  <div class="mt-4">
                    <textarea
                      id="additional-info"
                      name="additional-info"
                      rows="3"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-md sm:leading-6"
                    ></textarea>
                  </div>
                </div>
                <button className='bg-green-primary py-2 px-6 rounded-lg font-semibold mx-auto text-white'>Save Address</button>
              </form>
            </section>

</>
  )
}

export default Billing_address