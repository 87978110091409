import React, { useContext } from 'react'
import ProductsArray from '../Components/ProductsArray'
import { AppContext } from '../Services/Context/Appcontext'


const Wishlist = () => {
  const {products} = useContext(AppContext)
  return (
    <>
    <div className='px-4 sm:px-[50px] mt-10'>
        <h1 className='text-lg font-medium'>Your Wishlist</h1>
        <hr className=' w-full border-black mt-5'/>
    </div>
    <div className='sm:content hidden mt-20 '>
      <ProductsArray array={products} col={4} />
    </div>
    {/* This is for mobile */}
    <div className='content sm:hidden mt-20 '>
      <ProductsArray array={products} col={2} />
    </div>
    </>
  )
}

export default Wishlist